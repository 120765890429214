import Navbar from "components/navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "utils/ProtectedRoute";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { themeAtom } from "recoil/atoms/theme";
import { ThemeProvider } from "styled-components";
import Header from "components/header/Header";
import GlobalStyle from "utils/GlobalStyles";
import AlertMessage from "components/alert/AlertMessage";
import { getUser, cookie } from "utils/helpers/CookiesHelper";
import { useState } from "react";

import Home from "./pages/home/Home";
import Admin from "./pages/admin/Users";
import ContactUs from "./pages/contactUs/ContactUs";
import Downloads from "./pages/downloads/Downloads";
import News from "./pages/news/News";
import PublicFiles from "./pages/publicFiles/PublicFiles";
import TradeNotifications from "./pages/tradeNotifications/TradeNotifications";
import Uploads from "./pages/uploads/Uploads";
import Resources from "./pages/resources/Resources";
import VolumeLog from "./pages/volumeDisputes/VolumeLog";
import ManageNSP from "./pages/nspManage/ManageNSP";
import NSPLog from "./pages/nspLogs/NSPLog";
import NSPMeterUpdates from "./pages/nspMeterUpdates/NSPMeterUpdates";
import Preferences from "./pages/preference/Preferences";
import FileChecker from "./pages/fileChecker/FileChecker";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import LogOut from "./pages/logInOut/LogOut";
import Monitor from "./pages/monitor/Monitor";

const StyledApp = styled.div`
  display: flex;
  margin-top: 60px;

  .content {
    margin-left: 70px;
    height: calc(100vh - 60px );
    width:  calc(100vw - 70px);
    overflow-y: hidden;

    @media only screen and (max-width: 640px) {
      margin-left: 0px;
    }

    @media only screen and (max-width: 1000px) {
      width 100%;
    }
  }
`;

function App() {
  const [themeMode, setThemeMode] = useRecoilState(themeAtom);
  const [user, setUser] = useState(getUser());

  cookie.addChangeListener(() => {
    setUser(getUser(), {
      path: "/",
      secure: true,
      maxAge: 60 * 60 * 2,
    });
  });

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <BrowserRouter>
        <Header />
        <AlertMessage />
        <StyledApp>
          <Navbar />

          <div className={"content"} id={"content"}>
            <Routes>
              <Route exact path="/" element={<Home />} />

              <Route exact path="/news" element={<News />} />
              <Route exact path="/public_files" element={<PublicFiles />} />
              <Route exact path="/contact" element={<ContactUs />} />
              <Route exact path="/resources" element={<Resources />} />
              <Route exact path={`/password_resets/:id`} element={<ResetPassword />} />

              <Route exact path="/log_out" element={<LogOut />} />

              <Route element={<ProtectedRoute isAllowed={user.logged_in} />}>
                <Route exact path="/preferences" element={<Preferences themeMode={themeMode} setThemeMode={setThemeMode} />} />
                <Route exact path="/upload_files" element={<Uploads />} />
                <Route exact path="/download_files" element={<Downloads />} />
                <Route exact path="/trade_notifications" element={<TradeNotifications />} />
                <Route exact path="/volume_dispute_logs" element={<VolumeLog />} />
                <Route exact path="/manage_nsp" element={<ManageNSP />} />
                <Route exact path="/checker_files" element={<FileChecker />} />
              </Route>

              <Route element={<ProtectedRoute isAllowed={user.logged_in && user.authorised_to_nsp_ops} />}>
                <Route exact path="/nsp_log" element={<NSPLog />} />
                <Route exact path="/nsp_meter_updates" element={<NSPMeterUpdates />} />
              </Route>

              <Route element={<ProtectedRoute isAllowed={user.logged_in && user.admin} />}>
                <Route exact path="/users" element={<Admin />} />
              </Route>

              <Route exact path="/monitor" element={<Monitor />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </StyledApp>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
