import TableComponent from "components/helperComponents/TableComponent";
import InfoComponent from "components/helperComponents/InfoComponent";
import { decode } from "html-entities";

interface Types {
  data: any;
}

const VolumeDisputeInfo = ({ data }: Types) => {
  return (
    <InfoComponent>
      <table>
        <tbody>
          <TableComponent title={"Reference number:"} message={data.vlds_id} />
          <TableComponent title={"Status:"} message={data.nste_name} />
          <TableComponent
            title={"Raised by:"}
            message={decode(data.raised_by, { level: "html5" })}
          />
          <TableComponent title={"Consumption period(s):"} message={data.cnpd} />
          <TableComponent title={"Affected NSP(s):"} message={data.nsps} />
          <TableComponent title={"Details of dispute:"} message={data.dispute_details} />
          <TableComponent title={"Contact details:"} message={data.contact_details} />
          <TableComponent title={"Rm comments:"} message={data.comments_for_part} />
        </tbody>
      </table>
    </InfoComponent>
  );
};

export default VolumeDisputeInfo;
