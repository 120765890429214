import NewsInfo from "pages/news/NewsInfo";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { v4 as uuid } from "uuid";
import { headerAtom } from "recoil/atoms/header";
import Modal from "components/modal/Modal";
import { themeAtom } from "recoil/atoms/theme";
import { getData } from "utils/helpers/FetchHelper";
import { Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

const StyledNews = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const News = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState(null);
  const [rowOpen, setRowOpen] = useState(false);
  const theme = useRecoilValue(themeAtom);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);

  const columns = [
    {
      field: "created_at",
      headerName: "Created Date",
      type: "date",
      flex: 0,
    },
    {
      field: "subject",
      headerName: "Subject",
      type: "string",
      flex: 1,
    },
  ];

  useEffect(() => {
    setHeader({
      title: "News",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setHeader]);

  const fetchData = () => {
    setLoading(true);
    getData(`/api/v1/news/all_news_articles`, setData, setLoading, setAlert);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarExport
          csvOptions={{
            fileName: "news_export",
          }}
        />
        <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledNews>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          setRow(e.row);
          setRowOpen(true);
        }}
      />
      <Modal onClose={() => setRowOpen(false)} open={rowOpen} width={"50vw"}>
        <NewsInfo data={row} />
      </Modal>
    </StyledNews>
  );
};
export default News;
