import { useState, useEffect } from "react";
import styled from "styled-components";
import { alertAtom } from "recoil/atoms/alert";
import { v4 as uuid } from "uuid";
import { Download } from "@mui/icons-material";
import IconButton from "components/inputs/IconButton";
import { headerAtom } from "recoil/atoms/header";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { downloadData, getData } from "utils/helpers/FetchHelper";
import { Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

const StyledPublicFiles = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const PublicFiles = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);

  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      type: "string",
      flex: 3,
    },
    {
      field: "fty_short_description",
      headerName: "Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.map((item: any) => item.fty_short_description))],
      flex: 1,
    },
    {
      field: "received_datetime",
      headerName: "Date/Time",
      type: "dateTime",
      flex: 1,
      valueFormatter: (value: any) => new Date(value.value).toLocaleString("en-NZ"),
    },
    {
      field: "action",
      headerName: "Download",
      type: "string",
      flex: 0,
      renderCell: (params: { row: { id: string; filename: string } }) => (
        <IconButton icon={<Download />} disabled={false} color={"black"} onClick={() => downloadData(`/api/v1/public_files/download/${params.row.id}`, setAlert, params.row.filename)} />
      ),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Public Files",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setHeader]);

  const fetchData = () => {
    setLoading(true);
    getData(`/api/v1/public_files/all_public_files`, setData, setLoading, setAlert);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarExport
          csvOptions={{
            fileName: "public_files_export",
          }}
        />
        <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledPublicFiles>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
        }}
        rows={data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </StyledPublicFiles>
  );
};

export default PublicFiles;
