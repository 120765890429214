import { useEffect, useState } from "react";
import TextButton from "components/inputs/TextButton";
import TextField from "components/inputs/TextField";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { headerAtom } from "recoil/atoms/header";
import { postData } from "utils/helpers/FetchHelper";

const ResetPasswordBox = styled.div`
  vertical-align: top;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;

  .reset-password-input {
    text-align: center;
    padding: 1rem;
    background: ${({ theme }) => theme.clearWhite};
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    width: 30%;
    min-width: 320px;
  }
`;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const navigate = useNavigate();
  const { id } = useParams();
  const [resetPassword, setResetPassword] = useState({
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    setHeader({
      title: "Reset Password",
      description: "",
    });
  }, [setHeader]);

  const handleUpdate = (value: any, field: any) => {
    setResetPassword((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (resetPassword.password !== resetPassword.password_confirmation) {
      setAlert({
        type: "error",
        message: "Passwords must match",
        timeout: 5000,
      });
      setLoading(false);
      return;
    }

    postData(
      `/api/v1/password_resets/${id}`,
      JSON.stringify(resetPassword),
      setLoading,
      setAlert,
      thenRun
    );
  };

  const thenRun = () => {
    navigate("/");
  };

  return (
    <ResetPasswordBox>
      <form onSubmit={handleSubmit} className={"reset-password-input"}>
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "password")}
          title={"Password"}
          required={true}
          disabled={false}
          value={resetPassword.password}
          id={"password"}
          type={"password"}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "password_confirmation")}
          title={"Password Confirmation"}
          required={true}
          disabled={false}
          value={resetPassword.password_confirmation}
          id={"password_confirmation"}
          type={"password"}
        />

        <TextButton type={"submit"} title={"Update Password"} loading={loading} />
      </form>
    </ResetPasswordBox>
  );
};
export default ResetPassword;
