import { useEffect, useState } from "react";
import styled from "styled-components";
import TextButton from "components/inputs/TextButton";
import TextField from "components/inputs/TextField";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { headerAtom } from "recoil/atoms/header";
import { postData } from "utils/helpers/FetchHelper";

const StyledContactUs = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  color: ${({ theme }) => theme.textPrimary};

  .inner-contactus-content {
    width: 50%;
    min-width: 320px;
    text-align: center;
    padding: 1rem;
    background: ${({ theme }) => theme.clearWhite};
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  }

  .contactus-details{
    width 20%;
    display: inline-block;
    margin: 1rem;
  }

  table {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    th, td{
      text-align: center;
      padding: 0 1rem;

      .contact-email{
        color: ${({ theme }) => theme.primary};
      }
    }
  }
`;

const ContactUs = () => {
  const setHeader = useSetRecoilState(headerAtom);
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    setHeader({
      title: "Contact",
      description: "",
    });
  }, [setHeader]);

  const handleUpdate = (value: any, field: string) => {
    setContactInfo((lp: any) => {
      let tmp = { ...lp };

      tmp[field] = value;
      return tmp;
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postData(
      `/api/v1/contact/contact_us`,
      JSON.stringify(contactInfo),
      setLoading,
      setAlert,
      thenRun
    );
  };

  const thenRun = () => {
    setContactInfo({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <StyledContactUs>
      <div className={"inner-contactus-content"}>
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "name")}
            title={"Name"}
            required={true}
            disabled={false}
            value={contactInfo.name}
            id={"name"}
            type={"text"}
          />
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "email")}
            title={"Email"}
            required={true}
            disabled={false}
            value={contactInfo.email}
            id={"email"}
            type={"email"}
          />
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "message")}
            title={"Message"}
            required={true}
            disabled={false}
            value={contactInfo.message}
            id={"message"}
            type={"text"}
            rows={6}
            multiline={true}
          />
          <TextButton type={"submit"} title={"Submit"} loading={loading} />
        </form>

        <table>
          <tbody>
            <tr>
              <th>Telephone</th>
              <th>Email</th>
            </tr>
            <tr>
              <td>04 498 0044</td>
              <td>
                <a href="mailto:rm@nzx.com" className={"contact-email"}>
                  rm@nzx.com
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </StyledContactUs>
  );
};
export default ContactUs;
