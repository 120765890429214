import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { alertAtom } from "recoil/atoms/alert";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { getUser } from "utils/helpers/CookiesHelper";

interface Types {
  isAllowed: boolean;
  redirectPath: string;
  children: object;
}

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }: Types) => {
  const location = useLocation();
  const setAlert = useSetRecoilState(alertAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getUser().logged_in) {
      setAlert({
        type: "warning",
        message: "You session has expired, please log in to continue",
        timeout: 5000,
      });
      navigate("/");
    }
  }, [location, navigate, setAlert]);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
