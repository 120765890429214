import { Fragment, useState } from "react";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextButton from "components/inputs/TextButton";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { removeEmptyString } from "utils/helpers/Helper";
import TextField from "components/inputs/TextField";
import { putData } from "utils/helpers/FetchHelper";

const StyledConfirm = styled.form`
  .user-form-buttons {
    display: flex;
  }
`;

interface Types {
  setShow: any;
  row: any;
  participants: any;
}

const EditUser = ({ setShow, row, participants }: Types) => {
  const setAlert = useSetRecoilState(alertAtom);

  const [userData, setUserData] = useState({
    id: row.id,
    forename: row.forename,
    surname: row.surname,
    part_code: row.part_code,
    company_name: row.company_name,
    email: row.email,
    address1: row.address1,
    address2: row.address2,
    address3: row.address3,
    city: row.city,
    country: row.country,
    postcode: row.postcode,
    mobile: row.mobile,
    phone: row.phone,
    failed_login_count: row.failed_login_count,
    username: row.username,
    password: row.password,
    passwordConfirmation: row.passwordConfirmation,
  });

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleUpdate = (value: any, field: any) => {
    setUserData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const editUser = (e: any) => {
    e.preventDefault();
    setNewLoading(true);

    putData(
      `/api/v1/user/update`,
      JSON.stringify(removeEmptyString(userData)),
      setNewLoading,
      setAlert,
      setShow
    );
  };

  const handleDelete = (id: any) => {
    setDeleteLoading(true);

    fetch(`/api/v1/user/destroy/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAlert({
          type: data.alert.type,
          message: data.alert.message,
          timeout: 5000,
        });
        setDeleteLoading(false);
        setShow();
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Error making request, please try again",
          timeout: 5000,
        });
        setDeleteLoading(false);
      });
  };

  return (
    <StyledConfirm onSubmit={editUser}>
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "part_code");
          const name = participants.find((o: any) => o.code === e.target.value).name;
          handleUpdate(name ? name : "", "company_name");
        }}
        required={true}
        value={userData.part_code}
        id={"part_code"}
        label={"Participant"}
        items={participants.map((val: { code: string; name: string }, index: number) => (
          <MenuItem key={index} value={val.code}>
            {`${val.code} | ${val.name}`}
          </MenuItem>
        ))}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "forename")}
        title={"Name"}
        required={true}
        disabled={false}
        value={userData.forename}
        id={"forename"}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "surname")}
        title={"Surname"}
        required={true}
        disabled={false}
        value={userData.surname}
        id={"surname"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "email")}
        title={"Email"}
        required={true}
        disabled={false}
        value={userData.email}
        id={"email"}
        type={"email"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "mobile")}
        title={"Mobile"}
        required={false}
        disabled={false}
        value={userData.mobile}
        id={"mobile"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "phone")}
        title={"Phone"}
        required={false}
        disabled={false}
        value={userData.phone}
        id={"phone"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "failed_login_count")}
        title={"Failed Login Attempts"}
        required={false}
        disabled={false}
        value={userData.failed_login_count}
        id={"failedLogin"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address1")}
        title={"Address 1"}
        required={false}
        disabled={false}
        value={userData.address1}
        id={"address1"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address2")}
        title={"Address 2"}
        required={false}
        disabled={false}
        value={userData.address2}
        id={"address2"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address3")}
        title={"Address 3"}
        required={false}
        disabled={false}
        value={userData.address3}
        id={"address3"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "city")}
        title={"City"}
        required={false}
        disabled={false}
        value={userData.city}
        id={"city"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "country")}
        title={"country"}
        required={false}
        disabled={false}
        value={userData.country}
        id={"country"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "postcode")}
        title={"Postcode"}
        required={false}
        disabled={false}
        value={userData.postcode}
        id={"postcode"}
      />

      <div className="user-form-buttons">
        <TextButton type={"submit"} title={"Submit"} loading={newLoading} />

        <Fragment>
          {!deleteConfirm && (
            <TextButton
              type={"button"}
              title={"Delete User"}
              onClick={() => setDeleteConfirm(true)}
            />
          )}
          {deleteConfirm && (
            <TextButton
              type={"button"}
              title={"Are you sure"}
              onClick={() => handleDelete(row.id)}
              loading={deleteLoading}
            />
          )}
        </Fragment>
      </div>
    </StyledConfirm>
  );
};

export default EditUser;
