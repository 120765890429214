import React from "react";
import styled, { keyframes } from "styled-components";
const bouncedelay = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  width: 4px;
  border-width: 0 4px 60px 4px;
  border-style: none solid solid;
  border-color: transparent transparent ${({ theme }) => theme.primary};
  position: relative;
  padding-top: 50px;
  margin: 0 auto 24px auto;
  left: 0;
  right: 0;
  top: 0;
`;

const ItemFirst = styled.div`
  height: 0;
  width: 2px;
  border-width: 40px 2px 0px 2px;
  border-style: solid solid none;
  border-color: ${({ theme }) => theme.primary} transparent transparent;
  transform-origin: 0 -2px;
  transform: rotate(60deg);
  position: absolute;
`;

const ItemSecord = styled.div`
  height: 0;
  width: 2px;
  border-width: 40px 2px 0px 2px;
  border-style: solid solid none;
  border-color: ${({ theme }) => theme.primary} transparent transparent;
  transform-origin: 2px -1px;
  transform: rotate(180deg);
  position: absolute;
`;

const ItemThree = styled.div`
  height: 0;
  width: 2px;
  border-width: 40px 2px 0px 2px;
  border-style: solid solid none;
  border-color: ${({ theme }) => theme.primary} transparent transparent;
  transform-origin: 5px 0;
  transform: rotate(300deg);
  position: absolute;
`;

const Center = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  transform: translateX(-2px) translateY(-4px);
  position: absolute;
  border-radius: 127px;
  background: ${({ theme }) => theme.primary};
`;

const Con = styled.div`
  position: relative;
  animation: ${bouncedelay} 2s infinite linear;
`;

const LoadingSpinner = () => {
  return (
    <>
      <LoadingContainer>
        <Center />
        <Con>
          <ItemFirst />
          <ItemSecord />
          <ItemThree />
        </Con>
      </LoadingContainer>
    </>
  );
};

export default LoadingSpinner;
