import { useState } from "react";
import TextButton from "components/inputs/TextButton";
import { removeEmptyString } from "utils/helpers/Helper";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextField from "components/inputs/TextField";
import { range } from "utils/helpers/Helper";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/inputs/DatePicker";
import { putData } from "utils/helpers/FetchHelper";
import { updateDate, convertDates } from "utils/helpers/Helper";
import { checkForInvalidDate } from "utils/helpers/Helper";

interface Types {
  dropDowns: any;
  row: any;
  setShow: any;
}

const TradeNotificationModalEdit = ({ dropDowns, row, setShow }: Types) => {
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);

  const [tradeNotification, setTradeNotification] = useState({
    trnt_id: row.trnt_id,
    ctrt_code: row.ctrt_code ? row.ctrt_code : "",
    part_code: row.part_code ? row.part_code : "",
    contract_type: row.contract_type ? row.contract_type : "Purchase",
    nsp: row.nsp ? row.nsp : "",
    rtyp_code: row.rtyp_code ? row.rtyp_code : "",
    metering_type: row.metering_type ? row.metering_type : "",
    start_date: row.start_date ? row.start_date : "",
    start_tp: row.start_tp ? row.start_tp : "",
    end_date: row.end_date ? row.end_date : "",
    end_tp: row.end_tp ? row.end_tp : "",
  });

  const handleUpdate = (value: any, field: string) => {
    setTradeNotification((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const handleContractUpdate = (value: any) => {
    let contract = dropDowns.contracts.filter((x: any) => x.code === value)[0];
    handleUpdate(contract.code, "ctrt_code");
    handleUpdate(contract.contract_type, "contract_type");
  };

  const createOrUpdate = (e: any) => {
    e.preventDefault();

    const res = checkForInvalidDate(tradeNotification);
    if (res !== "") {
      setAlert({
        type: "error",
        message: `${res} must be a valid date`,
        timeout: 5000,
      });
      return;
    }

    setLoading(true);
    putData(
      `/api/v1/trade_notifications/update`,
      JSON.stringify(removeEmptyString(convertDates(tradeNotification))),
      setLoading,
      setAlert,
      setShow
    );
  };

  return (
    <form onSubmit={createOrUpdate}>
      <div>
        <DropDown
          onchange={(e: any) => {
            handleContractUpdate(e.target.value);
          }}
          value={tradeNotification.ctrt_code}
          id={"ctrt_code"}
          label={"Contract"}
          required={true}
          items={dropDowns.contracts.map((val: any, index: any) => (
            <MenuItem key={index} value={val.code}>
              {val.code}
            </MenuItem>
          ))}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "part_code")}
          title={"Participant"}
          required={false}
          disabled={true}
          value={tradeNotification.part_code}
          id={"part_code"}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "contract_type")}
          title={"Contract Type"}
          required={false}
          disabled={true}
          value={tradeNotification.contract_type}
          id={"contract_type"}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "nsp")}
          title={"NSP"}
          required={false}
          disabled={true}
          value={tradeNotification.nsp}
          id={"nsp"}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "rtyp_code")}
          title={"Recon Type"}
          required={false}
          disabled={true}
          value={tradeNotification.rtyp_code}
          id={"rtyp_code"}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "metering_type");
          }}
          value={tradeNotification.metering_type}
          id={"metering_type"}
          label={"Metering Type"}
          items={dropDowns.metering_types.map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />

        <DatePicker
          onChange={(e: any) => updateDate(e, handleUpdate, "start_date")}
          value={new Date(tradeNotification.start_date)}
          label={"Start date"}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "start_tp");
          }}
          value={tradeNotification.start_tp}
          id={"start_tp"}
          label={"Start TP"}
          items={range(1, 50).map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />

        <DatePicker
          onChange={(e: any) => updateDate(e, handleUpdate, "end_date")}
          value={new Date(tradeNotification.end_date)}
          label={"End date"}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "end_tp");
          }}
          value={tradeNotification.end_tp}
          id={"end_tp"}
          label={"End TP"}
          items={range(1, 50).map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
      </div>
      <TextButton type={"submit"} title={"Submit"} loading={loading} />
    </form>
  );
};

export default TradeNotificationModalEdit;
