import DownloadInfo from "pages/downloads/DownloadInfo";
import { useState, useEffect } from "react";
import IconButton from "components/inputs/IconButton";
import Modal from "components/modal/Modal";
import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { v4 as uuid } from "uuid";
import { Download } from "@mui/icons-material";
import { headerAtom } from "recoil/atoms/header";
import { themeAtom } from "recoil/atoms/theme";
import { downloadData, getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

const StyledDownloads = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const Downloads = () => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);
  const [row, setRow] = useState(null);
  const [rowOpen, setRowOpen] = useState(false);

  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      type: "string",
      flex: 3,
    },
    {
      field: "short_description",
      headerName: "Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.map((item: { short_description: string }) => item.short_description))],
      flex: 1,
    },
    {
      field: "status_display",
      headerName: "File Status",
      type: "string",
      flex: 1,
    },
    {
      field: "received_datetime",
      headerName: "Date/Time",
      type: "dateTime",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleString("en-NZ"),
    },

    {
      field: "action",
      headerName: "Download",
      type: "string",
      flex: 0,
      disableCellClick: true,
      renderCell: (params: { row: { id: string; filename: string } }) => (
        <IconButton icon={<Download />} disabled={false} color={"black"} onClick={() => downloadData(`/api/v1/downloads/download/${params.row.id}`, setAlert, params.row.filename)} />
      ),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Downloads",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setHeader]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);

      getData(`/api/v1/downloads/all_download_files`, setData, setLoading, setAlert);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarExport
          csvOptions={{
            fileName: "downloads_export",
          }}
        />
        <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledDownloads>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          if (!e.colDef.disableCellClick) {
            setRow(e.row);
            setRowOpen(true);
          }
        }}
      />
      <Modal onClose={() => setRowOpen(false)} open={rowOpen} width={"40vw"}>
        <DownloadInfo data={row} />
      </Modal>
    </StyledDownloads>
  );
};

export default Downloads;
