import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { navAtom } from "recoil/atoms/nav";
import { useRecoilState } from "recoil";
import { KeyboardArrowLeft, KeyboardArrowDown } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const StyledNavItem = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 25px;
  font-size: 15px;
  .title {
    padding-left: 10px;
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 20px;
    }
  }
  .icon-styles {
    svg {
      font-size: 20px;
    }
  }
  .item-header {
    display: flex;
    flex-direction: row;
  }
  &:hover {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.textPrimary};
    cursor: pointer;
  }
  &.active {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.textPrimary};
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

const StyledChildItem = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  .child-item {
    font-size: 13px;
    padding: 10px 0px 10px 50px;
    color: ${({ theme }) => theme.textSecondary};
    text-decoration: none;
    &:hover {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.textPrimary};
      cursor: pointer;
    }
    &.active {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.textPrimary};
    }
  }
`;

interface Types {
  title: string;
  icon: any;
  isOpen: boolean;
  children: any;
  show: boolean;
}

const NavItem = ({ title, icon, isOpen, children, show }: Types) => {
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useRecoilState(navAtom);

  useEffect(() => {
    if (!nav) {
      setOpen(false);
    }
  }, [nav]);

  const handleOpen = () => {
    if (!nav) {
      setNav(true);
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  return show ? (
    <>
      <Tooltip title={nav ? "" : title} placement="right" arrow TransitionComponent={Zoom}>
        <StyledNavItem onClick={() => handleOpen()} key={title}>
          <div className={"icon-styles"}>{icon}</div>
          <CSSTransition in={isOpen} timeout={200} classNames={"fade"} unmountOnExit>
            <div>
              <span className={"title"}>
                {title}
                {open ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
              </span>
            </div>
          </CSSTransition>
        </StyledNavItem>
      </Tooltip>
      <StyledChildItem>
        {open &&
          children.map((subItem: any, index: number) => (
            <NavLink
              key={index}
              to={subItem.path}
              className={"child-item"}
              onClick={() => setNav(false)}
            >
              {subItem.title}
            </NavLink>
          ))}
      </StyledChildItem>
    </>
  ) : null;
};
export default NavItem;
