import { useState, useEffect } from "react";
import LoadingSpinner from "components/loaders/LoadingSpinner";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { getData } from "utils/helpers/FetchHelper";

const Monitor = () => {
  const [data, setData] = useState({ primary_db: false });
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);

  useEffect(() => {
    getData(`/api/v1/monitor`, setData, setLoading, setAlert);
  }, [setAlert]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        Object.entries(data).map(([key, val]) => (
          <h2 key={key}>
            {key}: {val.toString()}
          </h2>
        ))
      )}
    </div>
  );
};

export default Monitor;
