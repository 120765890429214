import { useState } from "react";
import TextButton from "components/inputs/TextButton";
import { removeEmptyString } from "utils/helpers/Helper";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { range } from "utils/helpers/Helper";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/inputs/DatePicker";
import TextField from "components/inputs/TextField";
import { postData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { updateDate, convertDates } from "utils/helpers/Helper";
import { checkForInvalidDate } from "utils/helpers/Helper";

interface Types {
  dropDowns: any;
  setShow: any;
  tradeNotifications: any;
}

const TradeNotificationModalNew = ({ dropDowns, setShow, tradeNotifications }: Types) => {
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);
  const [tradeNotification, setTradeNotification] = useState({
    ctrt_code: "",
    contract_type: "",
    part_code: user.part_code,
    nsp: "",
    rtyp_code: "",
    nsp_rtyp: "",
    metering_type: "",
    start_date: new Date().toISOString(),
    start_tp: 1,
    end_date: "2525-12-31",
    end_tp: 48,
  });

  const handleUpdate = (value: any, field: any) => {
    setTradeNotification((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const handleContractUpdate = (value: any) => {
    let contract = dropDowns.contracts.filter((x: any) => x.code === value)[0];
    handleUpdate(contract.code, "ctrt_code");
    handleUpdate(contract.contract_type, "contract_type");
  };

  const handleNspRtypeUpdate = (value: any) => {
    let nsp_rtype = dropDowns.nsp_recon_type.filter((x: any) => x.nsp_rtyp === value)[0];
    handleUpdate(nsp_rtype.rtyp_code, "rtyp_code");
    handleUpdate(nsp_rtype.nsp_code, "nsp");
    handleUpdate(nsp_rtype.nsp_rtyp, "nsp_rtyp");
  };

  const identicalEntry = (tradeNotification: any) => {
    const filteredArray = tradeNotifications.filter((row: any) => {
      // Row has an Id and new entry doesnt so we remove it from the compare.
      let element = { ...row };
      delete element.trnt_id;

      let tn = { ...tradeNotification };
      delete tn.nsp_rtyp;

      return JSON.stringify(element) === JSON.stringify(tn);
    });
    if (filteredArray.length > 0) {
      setAlert({
        type: "error",
        message: "Trade Notification already exists",
        timeout: 5000,
      });
      return true;
    } else {
      return false;
    }
  };

  const overlappingDate = (tradeNotification: any) => {
    let tn = { ...tradeNotification };
    delete tn.start_date;
    delete tn.end_date;
    delete tn.nsp_rtyp;

    const filteredArray = tradeNotifications.filter((row: any) => {
      // Remove fields from element we dont want to check match
      let element = { ...row };
      delete element.trnt_id;
      delete element.start_date;
      delete element.end_date;

      // If the fileds match a row check the date range doesnt overlap
      if (JSON.stringify(element) === JSON.stringify(tn)) {
        if (
          tradeNotification.start_date > row.end_date ||
          tradeNotification.end_date < row.start_date
        ) {
          // Dates dont overlap so this is a legit entry
          return false;
        }
        // Overlapping dates,
        return true;
      }
      // Different entries
      return false;
    });

    if (filteredArray.length > 0) {
      setAlert({
        type: "error",
        message: "Trade Notification has overlapping dates with an existing Trade Notification",
        timeout: 5000,
      });

      return true;
    } else {
      return false;
    }
  };

  const checkExists = (tradeNotification: any) => {
    return identicalEntry(tradeNotification) || overlappingDate(tradeNotification);
  };

  const createOrUpdate = (e: any) => {
    e.preventDefault();

    const res = checkForInvalidDate(tradeNotification);

    if (res !== "") {
      setAlert({
        type: "error",
        message: `${res} must be a valid date`,
        timeout: 5000,
      });
      return;
    }

    setLoading(true);
    if (checkExists(tradeNotification)) {
      setLoading(false);
    } else {
      postData(
        `/api/v1/trade_notifications/create`,
        JSON.stringify(removeEmptyString(convertDates(tradeNotification))),
        setLoading,
        setAlert,
        setShow
      );
    }
  };

  return (
    <form onSubmit={createOrUpdate}>
      <div>
        <DropDown
          onchange={(e: any) => {
            handleContractUpdate(e.target.value);
          }}
          value={tradeNotification.ctrt_code}
          id={"ctrt_code"}
          label={"Contract"}
          required={true}
          items={dropDowns.contracts.map((val: any, index: any) => (
            <MenuItem key={index} value={val.code}>
              {val.code}
            </MenuItem>
          ))}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "part_code")}
          title={"Participant"}
          required={false}
          disabled={true}
          value={tradeNotification.part_code}
          id={"part_code"}
        />

        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "contract_type")}
          title={"Contract Type"}
          required={false}
          disabled={true}
          value={tradeNotification.contract_type}
          id={"contract_type"}
        />
        <DropDown
          onchange={(e: any) => {
            handleNspRtypeUpdate(e.target.value);
          }}
          value={tradeNotification.nsp_rtyp}
          id={"nsp"}
          label={"NSP"}
          required={true}
          disabled={false}
          items={dropDowns.nsp_recon_type.map((val: any, index: any) => (
            <MenuItem key={index} value={val.nsp_rtyp}>
              {val.nsp_rtyp}
            </MenuItem>
          ))}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "metering_type");
          }}
          value={tradeNotification.metering_type}
          id={"metering_type"}
          label={"Metering Type"}
          required={true}
          items={dropDowns.metering_types.map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
        <DatePicker
          onChange={(e: any) => updateDate(e, handleUpdate, "start_date")}
          value={new Date(tradeNotification.start_date)}
          label={"Start date"}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "start_tp");
          }}
          value={tradeNotification.start_tp}
          id={"start_tp"}
          label={"Start TP"}
          items={range(1, 50).map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />

        <DatePicker
          onChange={(e: any) => updateDate(e, handleUpdate, "end_date")}
          value={new Date(tradeNotification.end_date)}
          label={"End date"}
        />

        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "end_tp");
          }}
          value={tradeNotification.end_tp}
          id={"end_tp"}
          label={"End TP"}
          items={range(1, 50).map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
      </div>
      <TextButton type={"submit"} title={"Submit"} loading={loading} />
    </form>
  );
};

export default TradeNotificationModalNew;
