import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import logo404 from "assets/images/logo-404.png";
import TextButton from "components/inputs/TextButton";

export const StyledError404 = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin: 0.5rem;
    }
  }
`;

const Error404 = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <StyledError404>
      <div className="img">
        <img src={logo404} width="400px" alt="nzx logo"></img>
      </div>
      <div className="text">
        <h1>Page Not Found</h1>
        <TextButton type={"button"} title={"Back to safety"} onClick={goBack} />
      </div>
    </StyledError404>
  );
};

export default Error404;
