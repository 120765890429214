import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { removeUser } from "utils/helpers/CookiesHelper";

const LogOut = () => {
  const navigate = useNavigate();
  const setAlert = useSetRecoilState(alertAtom);

  useEffect(() => {
    try {
      setAlert({
        type: "success",
        message: "Successfully logged out",
        timeout: 5000,
      });
      navigate("/");
      removeUser();
    } catch (error) {
      setAlert({
        type: "error",
        message: "Error making request, please try again",
        timeout: 5000,
      });
      navigate("/");
    }
  }, [navigate, setAlert]);
};

export default LogOut;
