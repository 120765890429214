import { useEffect, useState } from "react";
import TableComponent from "components/helperComponents/TableComponent";
import InfoComponent from "components/helperComponents/InfoComponent";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { v4 as uuid } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { getData } from "utils/helpers/FetchHelper";

interface Types {
  row: any;
}
const FileCheckerInfo = ({ row }: Types) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);

  const columns = [
    {
      field: "created_date",
      headerName: "Date",
      type: "string",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleString("en-NZ"),
    },
    {
      field: "status_code",
      headerName: "Status",
      type: "string",
      flex: 0,
    },
    {
      field: "message",
      headerName: "Message",
      type: "string",
      flex: 2,
    },
  ];

  useEffect(() => {
    getData(`/api/v1/file_checker/file_checker_info/${row.id}`, setData, setLoading, setAlert);
  }, [row.id, setAlert]);

  return (
    <InfoComponent>
      <table>
        <tbody>
          <TableComponent title={"Filename:"} message={row.filename} />
          <TableComponent title={"Uploaded by User:"} message={row.uploaded_by_user} />
          <TableComponent title={"Uploaded by Participant:"} message={row.uploaded_by_part} />
          <TableComponent title={"Participant:"} message={row.sender} />
          <TableComponent title={"Recipient:"} message={row.recipient} />
          <TableComponent title={"Consumption Period:"} message={row.start_date} />
          <TableComponent title={"File Type:"} message={row.short_description} />
          <TableComponent title={"Run Date/Time:"} message={row.run_datetime} />
          <TableComponent title={"Received Date/Time:"} message={row.received_datetime} />
          <TableComponent title={"State:"} message={row.status_display} />
          <TableComponent title={"Acceptance date:"} message={row.acceptance_date} />
          <TableComponent title={"Detail rows:"} message={row.detail_datas} />
          <TableComponent title={"Validation Errors:"} message={row.warning_datas} />
        </tbody>
      </table>

      <div style={{ height: "300px" }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={() => uuid()}
          getRowHeight={() => "auto"}
          disableSelectionOnClick
          loading={loading}
        />
      </div>
    </InfoComponent>
  );
};

export default FileCheckerInfo;
