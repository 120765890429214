import { FormControl, InputLabel, Select } from "@mui/material";
import styled from "styled-components";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue } from "recoil";

const StyledDropDown = styled.div`
  margin: 0.5rem;
`;

interface Types {
  onchange: any;
  value: any;
  id: string;
  label: string;
  items: any;
  disabled?: boolean;
  required?: boolean;
}

const DropDown = ({ onchange, value, id, label, items, disabled, required }: Types) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <StyledDropDown>
      <FormControl size="small" required={required} fullWidth>
        <InputLabel sx={{ color: theme.inputLabelColor }} id={id}>
          {label}
        </InputLabel>
        <Select
          id={id}
          value={value}
          label={label}
          disabled={disabled}
          onChange={onchange}
          sx={{ color: theme.textPrimary }}
        >
          {items}
        </Select>
      </FormControl>
    </StyledDropDown>
  );
};

export default DropDown;
