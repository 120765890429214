import styled from "styled-components";
import { Close } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "components/inputs/IconButton";

const StyledModal = styled.div<StyledTypes>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-container {
    width: ${(props) => props.width};
    min-width: 320px;
  }

  .header {
    background: ${({ theme }) => theme.primary};
    display: flex;
    justify-content: flex-end;
    padding: 0.2rem;
    align-items: center;
    color: ${({ theme }) => theme.textPrimary};
  }

  .content {
    padding: 1rem;
    max-height: 70vh;
    overflow: auto;
    background: ${({ theme }) => theme.background};
  }

  svg {
    font-size: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;
interface StyledTypes {
  width: string;
}

interface Types {
  onClose: any;
  children?: any;
  width: string;
  open: boolean;
}

const Modals = ({ onClose, open, children, width }: Types) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledModal width={width}>
          <div className={"inner-container"}>
            <div className={"header"}>
              <IconButton icon={<Close />} disabled={false} onClick={onClose} />
            </div>

            <div className={"content"}>{children}</div>
          </div>
        </StyledModal>
      </Fade>
    </Modal>
  );
};

export default Modals;
