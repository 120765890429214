import styled from "styled-components";
import Button from "@mui/material/Button";
import { MouseEventHandler } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTextButton = styled.div`
  margin: 0.5rem;

  .loading-styles {
    display: flex;
    align-items: center;
    p {
      margin: 0 0.3rem;
    }
  }
`;

interface Types {
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: "text" | "outlined" | "contained" | undefined;
  type: "submit" | "button";
  loading?: boolean;
  children?: any;
  component?: any;
  disabled?: boolean;
}

const TextButton = ({
  title,
  onClick,
  variant = "contained",
  type,
  loading,
  children,
  component,
  disabled,
}: Types) => {
  return (
    <StyledTextButton>
      <Button
        onClick={onClick}
        type={type}
        className={"button"}
        disabled={disabled || loading}
        component={component}
        variant={variant}
      >
        {children}
        {loading ? (
          <div className={"loading-styles"}>
            <CircularProgress color="inherit" size={20} />
            <p>{title}</p>
          </div>
        ) : (
          title
        )}
      </Button>
    </StyledTextButton>
  );
};

export default TextButton;
