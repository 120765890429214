import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { navAtom } from "recoil/atoms/nav";
import { useRecoilState } from "recoil";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const StyledNavItem = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 25px;
  font-size: 15px;
  .title {
    padding-left: 10px;
  }
  .icon-styles {
    svg {
      font-size: 20px;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.textPrimary};
    cursor: pointer;
  }
  &.active {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.textPrimary};
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

interface Types {
  title: string;
  icon: any;
  isOpen: boolean;
  show: boolean;
  onClick: Function;
}

const NavItem = ({ title, icon, isOpen, onClick, show }: Types) => {
  const [nav, setNav] = useRecoilState(navAtom);

  const handClick = () => {
    setNav(false);
    onClick();
  };

  return show ? (
    <Tooltip title={nav ? "" : title} placement="right" arrow TransitionComponent={Zoom}>
      <StyledNavItem onClick={handClick} key={title}>
        <div className={"icon-styles"}>{icon}</div>
        <CSSTransition in={isOpen} timeout={200} classNames={"fade"} unmountOnExit>
          <span className={"title"}>{title}</span>
        </CSSTransition>
      </StyledNavItem>
    </Tooltip>
  ) : null;
};
export default NavItem;
