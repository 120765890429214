import { useState, useEffect } from "react";
import {
  nspFlowDirection,
  nspFlowDirectionUndo,
  removeEmptyString,
} from "utils/helpers/Helper";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextField from "components/inputs/TextField";
import { range } from "utils/helpers/Helper";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/inputs/DatePicker";
import TextButton from "components/inputs/TextButton";
import { postData } from "utils/helpers/FetchHelper";
import { updateDate, convertDates } from "utils/helpers/Helper";
import { checkForInvalidDate } from "utils/helpers/Helper";

interface Types {
  setShow: () => void;
  dropDownData: any;
}

const ManageNSPModalNew = ({ setShow, dropDownData }: Types) => {
  const [nspData, setNspData] = useState({
    rtyp_code: "",
    poc_code: "",
    nwk_code: "",
    x_flow_yn: "",
    i_flow_yn: "",
    start_date: new Date().toISOString(),
    start_tp: 1,
    end_date: "2525-12-31",
    end_tp: 48,
    address: "",
    island: "",
    bala_code: "",
    embedded_under_nsp_code: "",
    le_icp: "",
    diff_yn: "",
    incumbent_part_code: "",
    sb_icp: "",
    comments: "",
    operation: "ADD",
  });
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);

  const handleUpdate = (value: any, field: any) => {
    setNspData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  useEffect(() => {
    if (nspData.rtyp_code === "NP") {
      handleUpdate("Y", "x_flow_yn");
      handleUpdate("Y", "i_flow_yn");
      handleUpdate("", "le_icp");
      handleUpdate("", "diff_yn");
      handleUpdate("", "incumbent_part_code");
      handleUpdate("", "sb_icp");
    } else if (nspData.rtyp_code !== "EN") {
      handleUpdate("", "bala_code");
      handleUpdate("", "embedded_under_nsp_code");
      handleUpdate("", "le_icp");
      handleUpdate("", "diff_yn");
      handleUpdate("", "incumbent_part_code");
      handleUpdate("", "sb_icp");
    }
  }, [nspData.rtyp_code]);

  useEffect(() => {
    if (nspData.diff_yn === "N") {
      handleUpdate("", "incumbent_part_code");
      handleUpdate("", "sb_icp");
    }
  }, [nspData.diff_yn]);

  const newnsp = (e: any) => {
    e.preventDefault();

    const res = checkForInvalidDate(nspData);
    if (res !== "") {
      setAlert({
        type: "error",
        message: `${res} must be a valid date`,
        timeout: 5000,
      });
      return;
    }

    setLoading(true);
    postData(
      `/api/v1/nsp/create_nsp`,
      JSON.stringify(removeEmptyString(convertDates(nspData))),
      setLoading,
      setAlert,
      setShow
    );
  };

  const sortNSPCodes = () => {
    const setNSP = new Set();
    dropDownData.rtyp_codes.forEach((data: any) => {
      data["EDIT"].forEach(setNSP.add, setNSP);
    });
    return Array.from(setNSP);
  };

  return (
    <form onSubmit={newnsp}>
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "rtyp_code");
        }}
        value={nspData.rtyp_code}
        id={"rtyp_code"}
        label={"Recon type"}
        required={true}
        disabled={false}
        items={sortNSPCodes().map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "poc_code")}
        title={"POC code"}
        required={true}
        disabled={false}
        value={nspData.poc_code}
        id={"poc_code"}
        regex={"[A-Z]{3}[0-9]{4}"}
        regextText={
          "POC code should start with 3 capitalized letters, followed by 4 digits"
        }
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "nwk_code");
        }}
        value={nspData.nwk_code}
        id={"nwk_code"}
        label={"Network code"}
        disabled={false}
        required={true}
        items={dropDownData.nwk_codes.map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <DropDown
        onchange={(e: any) => {
          const flow: { x_flow_yn: string; i_flow_yn: string } =
            nspFlowDirectionUndo(e.target.value);

          handleUpdate(flow.x_flow_yn, "x_flow_yn");
          handleUpdate(flow.i_flow_yn, "i_flow_yn");
        }}
        value={nspFlowDirection(nspData.x_flow_yn, nspData.i_flow_yn)}
        id={"flow_direction"}
        label={"Flow direction"}
        required={true}
        disabled={nspData.rtyp_code === "NP"}
        items={["X only", "I only", "X and I"].map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <DatePicker
        onChange={(e: any) => updateDate(e, handleUpdate, "start_date")}
        value={new Date(nspData.start_date)}
        label={"Start date"}
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "start_tp");
        }}
        value={nspData.start_tp}
        id={"start_tp"}
        label={"Start TP"}
        items={range(1, 50).map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <DatePicker
        onChange={(e: any) => updateDate(e, handleUpdate, "end_date")}
        value={new Date(nspData.end_date)}
        label={"End date"}
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "end_tp");
        }}
        value={nspData.end_tp}
        id={"end_tp"}
        label={"End TP"}
        items={range(1, 50).map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address")}
        title={"Address"}
        required={true}
        disabled={false}
        value={nspData.address}
        regex={"^[A-Za-z 0-9]+$"}
        regextText={
          "Address should not contain special characters. Only letters, numbers and spaces"
        }
        id={"address"}
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "island");
        }}
        value={nspData.island}
        id={"island"}
        label={"Island"}
        disabled={false}
        required={true}
        items={[
          {
            name: "North Island",
            code: "NI",
          },
          { name: "South Island", code: "SI" },
        ].map((val: any, index: any) => (
          <MenuItem key={index} value={val.code}>
            {val.name}
          </MenuItem>
        ))}
      />
      {nspData.rtyp_code === "NP" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "bala_code");
          }}
          value={nspData.bala_code}
          id={"bala_code"}
          label={"Balancing Area"}
          required={true}
          disabled={false}
          items={dropDownData.np_bala_codes.map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "bala_code")}
          title={"Balancing Area"}
          required={true}
          value={nspData.bala_code}
          id={"bala_code"}
          disabled={false}
        />
      )}

      {["NP", "EN"].includes(nspData.rtyp_code) && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "embedded_under_nsp_code");
          }}
          value={nspData.embedded_under_nsp_code}
          id={"embedded_under_nsp_code"}
          label={"Parent GXP"}
          required={true}
          disabled={false}
          items={dropDownData.embedded_under_nsp_codes.map(
            (val: any, index: any) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            )
          )}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "le_icp")}
          title={"LE ICP"}
          required={false}
          disabled={nspData.rtyp_code !== "EN"}
          value={nspData.le_icp}
          id={"le_icp"}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "diff_yn");
          }}
          value={nspData.diff_yn}
          id={"diff_yn"}
          label={"Recon method"}
          required={true}
          items={[
            { name: "Global", code: "N" },
            { name: "Differencing", code: "Y" },
          ].map((val: any, index: any) => (
            <MenuItem key={index} value={val.code}>
              {val.name}
            </MenuItem>
          ))}
        />
      )}
      {nspData.diff_yn === "Y" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "incumbent_part_code");
          }}
          value={nspData.incumbent_part_code}
          id={"incumbent_part_code"}
          label={"SB Retailer"}
          required={true}
          items={dropDownData.incumbent_part_codes.map(
            (val: any, index: any) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            )
          )}
        />
      )}
      {!(nspData.diff_yn !== "Y") && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "sb_icp")}
          title={"SB ICP"}
          required={true}
          value={nspData.sb_icp}
          id={"sb_icp"}
          disabled={false}
        />
      )}
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "comments")}
        title={"Comments"}
        required={false}
        value={nspData.comments}
        id={"comments"}
        rows={5}
        multiline={true}
        disabled={false}
      />
      <TextButton type={"submit"} title={"Submit"} loading={loading} />
    </form>
  );
};

export default ManageNSPModalNew;
