import styled from "styled-components";

interface Types {
  children: any;
}

const StyledInfoComponent = styled.div`
  display: block;
  text-align: left;
  font-size: 0.8rem;
  margin: 0.5rem;
`;

const InfoComponent = ({ children }: Types) => {
  return <StyledInfoComponent>{children}</StyledInfoComponent>;
};

export default InfoComponent;
