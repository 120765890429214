import { useState, useEffect } from "react";
import NSPMeterUpdateModal from "pages/nspMeterUpdates/NSPMeterUpdateModal";
import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { themeAtom } from "recoil/atoms/theme";
import { headerAtom } from "recoil/atoms/header";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { useNavigate } from "react-router";

const StyledNspMeterUpdates = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const NSPMeterUpdates = () => {
  const navigate = useNavigate();
  const theme = useRecoilValue(themeAtom);
  const [editNSPMeter, setEditNSPMeter] = useState(false);
  const [row, setRow] = useState(undefined);
  const [past, setPast] = useState(false);
  const [current, setCurrent] = useState(true);
  const [future, setFuture] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const [data, setData] = useState({
    meps_providers: [],
    nsp_meter_updates: [],
  });
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "nsp_code",
      headerName: "NSP",
      type: "string",
      flex: 1,
    },

    {
      field: "description",
      headerName: "Description",
      type: "string",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      type: "string",
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "End Date",
      type: "string",
      flex: 1,
    },
    {
      field: "responsible_party_code",
      headerName: "Responsible Participant",
      type: "string",
      flex: 1,
    },
    {
      field: "metering_provider_code",
      headerName: "MEP",
      type: "string",
      flex: 1,
    },
    {
      field: "cert_status",
      headerName: "Certification Status",
      type: "string",
      flex: 1,
    },
    {
      field: "expiry_date",
      headerName: "Expiry date",
      type: "date",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleDateString("en-NZ"),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "NSP Meter Updates",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [editNSPMeter, setHeader]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);
      getData(`/api/v1/nsp/all_nsp_meter_updates`, setData, setLoading, setAlert, () => navigate("/"));
    }
  };

  const filteredData = data.nsp_meter_updates.filter((item: any) => {
    let allowed = false;

    if (item.nhis_time_state === "Prev") {
      allowed = past;
    }
    if (item.nhis_time_state === "Current") {
      allowed = current;
    }
    if (item.nhis_time_state === "Next") {
      allowed = future;
    }

    return allowed;
  });

  const processedData = filteredData.map((v: any) => {
    let tmp = { ...v };
    tmp.nsp_code = v.nsp_code.slice(0, 7) + "-" + v.nsp_code.slice(7);

    return tmp;
  });

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarExport
          csvOptions={{
            fileName: "nsp_meter_updates_export",
          }}
        />
        <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
          Refresh
        </Button>
        <Checkbox onChange={() => setPast(!past)} checked={past} />
        <div>Past</div>
        <Checkbox onChange={() => setCurrent(!current)} checked={current} />
        <div>Current</div>
        <Checkbox onChange={() => setFuture(!future)} checked={future} />
        <div>Future</div>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledNspMeterUpdates>
      <Box
        sx={{
          height: "100%",
          "& .super-app-theme--Y": {
            color: "#0061A2",
            textDecoration: "underline",
          },
        }}
      >
        <DataGrid
          sx={{
            paddingLeft: "1rem",
            paddingTop: "0.5rem",
            border: 0,
            borderRadius: 0,
            background: theme.clearWhite,
            color: theme.textPrimary,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          getRowClassName={(params: any) => `super-app-theme--${params.row.editable_yn}`}
          rows={processedData}
          columns={columns}
          getRowId={() => uuid()}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          loading={loading}
          onCellClick={(e: any) => {
            if (e.row.editable_yn === "Y") {
              setRow(e.row);
              setEditNSPMeter(true);
            }
          }}
        />
      </Box>
      <Modal onClose={() => setEditNSPMeter(false)} open={editNSPMeter} width={"40vw"}>
        <NSPMeterUpdateModal row={row} setShow={() => setEditNSPMeter(false)} mepsProviders={data.meps_providers} />
      </Modal>
    </StyledNspMeterUpdates>
  );
};

export default NSPMeterUpdates;
