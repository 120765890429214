import { Fragment, useState } from "react";
import TextButton from "components/inputs/TextButton";
import { removeEmptyString } from "utils/helpers/Helper";
import InfoComponent from "components/helperComponents/InfoComponent";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import TextField from "components/inputs/TextField";
import { postData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { decode } from "html-entities";

interface Types {
  setShow: any;
}

const VolumeDisputeModal = ({ setShow }: Types) => {
  const [haveInvoiced, setHaveInvoiced] = useState("Yes");
  const user = getUser();

  const [volumeDisputeData, setVolumeDisputeData] = useState({
    raised_by: decode(user.company_name, { level: "html5" }),
    cnpd: "",
    cnpd_invoiced_yn: "N",
    nsps: "",
    dispute_details: "",
    contact_details: "",
  });
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);

  const handleUpdate = (value: any, field: any) => {
    setVolumeDisputeData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const submitDispute = (e: any) => {
    e.preventDefault();
    setLoading(true);

    postData(
      `/api/v1/volume_disputes/create_volume_dispute`,
      JSON.stringify(removeEmptyString(volumeDisputeData)),
      setLoading,
      setAlert,
      setShow
    );
  };

  return (
    <form onSubmit={submitDispute}>
      <DropDown
        onchange={(e: any) => setHaveInvoiced(e.target.value)}
        value={haveInvoiced}
        id={"have_invoiced"}
        label={"Have the consumption period(s) been invoiced?"}
        items={["Yes", "No"].map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />

      {haveInvoiced === "Yes" && (
        <InfoComponent>
          <div>Volume disputes can only be raised before invoicing.</div>
          <div>
            If consumption period has already been invoiced you can raise an invoice dispute with
            the Clearing Manager
          </div>
        </InfoComponent>
      )}

      {haveInvoiced === "No" && (
        <Fragment>
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "raised_by")}
            title={"Raised by"}
            required={false}
            disabled={true}
            value={volumeDisputeData.raised_by}
            id={"raised_by"}
          />
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "cnpd")}
            title={"Consumption periods affected"}
            required={true}
            disabled={false}
            value={volumeDisputeData.cnpd}
            id={"cnpd"}
          />

          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "nsps")}
            title={"Affected NSPs"}
            required={true}
            disabled={false}
            value={volumeDisputeData.nsps}
            id={"nsps"}
          />
          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "dispute_details")}
            title={"Details of dispute"}
            required={true}
            disabled={false}
            value={volumeDisputeData.dispute_details}
            id={"dispute_details"}
            rows={3}
            multiline={true}
          />

          <TextField
            onChange={(e: any) => handleUpdate(e.target.value, "contact_details")}
            title={"Contact Details"}
            required={true}
            disabled={false}
            value={volumeDisputeData.contact_details}
            id={"contact_details"}
            rows={3}
            multiline={true}
          />

          <TextButton type={"submit"} title={"Send to Reconciliation Manager"} loading={loading} />
        </Fragment>
      )}
    </form>
  );
};

export default VolumeDisputeModal;
