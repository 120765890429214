import { getUser } from "utils/helpers/CookiesHelper";

export const getData = (url: string, setData: any, setLoading: any, setAlert: any, thenNavigate?: Function) => {

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUser().jwt}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.alert.type === "unauthorised") {
        setAlert({
          type: "error",
          message: data.alert.message,
          timeout: 5000,
        });
        if (thenNavigate){thenNavigate()}
      }
      else if (data.alert.type === "success") {
        setData(data.content);
      } else {
        setAlert({
          type: data.alert.type,
          message: data.alert.message,
          timeout: 5000,
        });
      }
      setLoading(false);
    })
    .catch(() => {
      setAlert({
        type: "error",
        message: "Error making request, please try again",
        timeout: 5000,
      });
      setLoading(false);
    });
};

export const postData = (url: string, body: any, setLoading: any, setAlert: any, thenRun: any) => {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUser().jwt}`,
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.alert.type === "success") {
        thenRun();
      }
      setAlert({
        type: data.alert.type,
        message: data.alert.message,
        timeout: 5000,
      });
      setLoading(false);
    })
    .catch(() => {
      setAlert({
        type: "error",
        message: "Error making request, please try again",
        timeout: 5000,
      });
      setLoading(false);
    });
};

export const putData = (url: string, body: any, setLoading: any, setAlert: any, thenRun: any) => {
  fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUser().jwt}`,
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.alert.type === "success") {
        thenRun();
      }
      setAlert({
        type: data.alert.type,
        message: data.alert.message,
        timeout: 5000,
      });
      setLoading(false);
    })
    .catch(() => {
      setAlert({
        type: "error",
        message: "Error making request, please try again",
        timeout: 5000,
      });
      setLoading(false);
    });
};

export const downloadData = (url: string, setAlert: any, filename: any) => {
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getUser().jwt}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      let v = document.createElement("a");
      v.href = window.URL.createObjectURL(blob);
      v.download = filename;
      v.click();
    })
    .catch(() => {
      setAlert({
        type: "error",
        message: `An error occured when trying to download file`,
        timeout: 5000,
      });
    });
};
