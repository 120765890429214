import { useState } from "react";
import TextButton from "components/inputs/TextButton";
import {
  nspFlowDirection,
  nspFlowDirectionUndo,
  removeEmptyString,
} from "utils/helpers/Helper";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextField from "components/inputs/TextField";
import { range } from "utils/helpers/Helper";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/inputs/DatePicker";
import { putData } from "utils/helpers/FetchHelper";
import { updateDate, convertDates } from "utils/helpers/Helper";
import { checkForInvalidDate } from "utils/helpers/Helper";

interface Types {
  setShow: () => void;
  dropDownData: any;
  row: any;
  gridOwner: boolean;
}

const ManageNSPModalEdit = ({
  setShow,
  dropDownData,
  row,
  gridOwner,
}: Types) => {
  const [nspData, setNspData] = useState({
    rtyp_code: row.rtyp_code,
    poc_code: row.poc_code,
    nwk_code: row.nwk_code,
    x_flow_yn: row.x_flow_yn,
    i_flow_yn: row.i_flow_yn,
    start_date: row.start_date,
    start_tp: row.start_tp,
    end_date: row.end_date,
    end_tp: row.end_tp,
    address: row.address,
    island: row.island,
    bala_code: row.bala_code,
    embedded_under_nsp_code: row.embedded_under_nsp
      ? row.embedded_under_nsp
      : "",
    le_icp: row.le_icp ? row.le_icp : "",
    diff_yn: row.diff_yn ? row.diff_yn : "",
    incumbent_part_code: row.incumbent_part_code ? row.incumbent_part_code : "",
    sb_icp: row.sb_icp ? row.sb_icp : "",
    comments: row.comments,
    operation: "EDIT",
  });
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);

  const handleUpdate = (value: any, field: any) => {
    setNspData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const formatNSP = (nsp: any) => {
    let tmp = { ...nsp };

    if (tmp.rtyp_code === "NP") {
      tmp.x_flow_yn = "Y";
      tmp.i_flow_yn = "Y";
      tmp.le_icp = "";
      tmp.diff_yn = "";
      tmp.incumbent_part_code = "";
      tmp.sb_icp = "";
    } else if (tmp.rtyp_code !== "EN") {
      tmp.bala_code = "";
      tmp.embedded_under_nsp_code = "";
      tmp.le_icp = "";
      tmp.diff_yn = "";
      tmp.incumbent_part_code = "";
      tmp.sb_icp = "";
    }

    return convertDates(tmp);
  };

  const editnsp = (e: any) => {
    e.preventDefault();

    const res = checkForInvalidDate(nspData);
    if (res !== "") {
      setAlert({
        type: "error",
        message: `${res} must be a valid date`,
        timeout: 5000,
      });
      return;
    }

    setLoading(true);

    putData(
      `/api/v1/nsp/update_nsp`,
      JSON.stringify(removeEmptyString(formatNSP(nspData))),
      setLoading,
      setAlert,
      setShow
    );
  };

  const sortNSPCodes = () => {
    const setNSP = new Set();
    dropDownData.rtyp_codes.forEach((data: any) => {
      data["EDIT"].forEach(setNSP.add, setNSP);
    });
    return Array.from(setNSP);
  };

  return (
    <form onSubmit={editnsp}>
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "rtyp_code");
        }}
        value={nspData.rtyp_code}
        id={"rtyp_code"}
        label={"Recon type"}
        required={true}
        disabled={false}
        items={sortNSPCodes().map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "poc_code")}
        title={"POC code"}
        required={true}
        disabled={true}
        value={nspData.poc_code}
        id={"poc_code"}
        regex={"[A-Z]{3}[0-9]{4}"}
        regextText={
          "POC code should start with 3 capitalized letters, followed by 4 digits"
        }
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "poc_code")}
        title={"Network Code"}
        required={true}
        disabled={true}
        value={nspData.nwk_code}
        id={"nwk_code"}
      />
      <DropDown
        onchange={(e: any) => {
          const flow: { x_flow_yn: string; i_flow_yn: string } =
            nspFlowDirectionUndo(e.target.value);

          handleUpdate(flow.x_flow_yn, "x_flow_yn");
          handleUpdate(flow.i_flow_yn, "i_flow_yn");
        }}
        value={nspFlowDirection(nspData.x_flow_yn, nspData.i_flow_yn)}
        id={"flow_direction"}
        label={"Flow direction"}
        required={true}
        disabled={
          nspData.rtyp_code === "EN"
            ? false
            : gridOwner && nspData.rtyp_code !== "NP"
            ? false
            : true
        }
        items={["X only", "I only", "X and I"].map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <DatePicker
        onChange={(e: any) => updateDate(e, handleUpdate, "start_date")}
        value={new Date(nspData.start_date)}
        label={"Start date"}
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "start_tp");
        }}
        value={nspData.start_tp}
        id={"start_tp"}
        label={"Start TP"}
        items={range(1, 50).map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />

      <DatePicker
        onChange={(e: any) => updateDate(e, handleUpdate, "end_date")}
        value={new Date(nspData.end_date)}
        label={"End date"}
      />

      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "end_tp");
        }}
        value={nspData.end_tp}
        id={"end_tp"}
        label={"End TP"}
        items={range(1, 50).map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address")}
        title={"Address"}
        required={true}
        disabled={true}
        value={nspData.address}
        id={"address"}
        regex={"^[A-Za-z 0-9]+$"}
        regextText={
          "Address should not contain special characters. Only letters, numbers and spaces"
        }
      />
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "island");
        }}
        value={nspData.island}
        id={"island"}
        label={"Island"}
        disabled={true}
        required={true}
        items={[
          {
            name: "North Island",
            code: "NI",
          },
          { name: "South Island", code: "SI" },
        ].map((val: any, index: any) => (
          <MenuItem key={index} value={val.code}>
            {val.name}
          </MenuItem>
        ))}
      />
      {nspData.rtyp_code === "NP" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "bala_code");
          }}
          value={nspData.bala_code}
          id={"bala_code"}
          label={"Balancing Area"}
          required={true}
          disabled={false}
          items={dropDownData.np_bala_codes.map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "bala_code")}
          title={"Balancing Area"}
          required={true}
          value={nspData.bala_code}
          id={"bala_code"}
          disabled={false}
        />
      )}
      {["GG", "GN", "GD"].includes(nspData.rtyp_code) && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "bala_code")}
          title={"Balancing Area"}
          required={false}
          value={nspData.bala_code}
          id={"bala_code"}
          disabled={false}
        />
      )}

      {["NP", "EN"].includes(nspData.rtyp_code) && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "embedded_under_nsp_code");
          }}
          value={nspData.embedded_under_nsp_code}
          id={"embedded_under_nsp_code"}
          label={"Parent GXP"}
          required={true}
          disabled={false}
          items={dropDownData.embedded_under_nsp_codes.map(
            (val: any, index: any) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            )
          )}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "le_icp")}
          title={"LE ICP"}
          required={false}
          disabled={nspData.rtyp_code !== "EN"}
          value={nspData.le_icp}
          id={"le_icp"}
        />
      )}
      {nspData.rtyp_code === "EN" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "diff_yn");
            if (e.target.value === "N") {
              handleUpdate("", "incumbent_part_code");
              handleUpdate("", "sb_icp");
            }
          }}
          value={nspData.diff_yn}
          id={"diff_yn"}
          label={"Recon method"}
          required={true}
          items={[
            { name: "Global", code: "N" },
            { name: "Differencing", code: "Y" },
          ].map((val: any, index: any) => (
            <MenuItem key={index} value={val.code}>
              {val.name}
            </MenuItem>
          ))}
        />
      )}
      {nspData.diff_yn === "Y" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "incumbent_part_code");
          }}
          value={nspData.incumbent_part_code}
          id={"incumbent_part_code"}
          label={"SB Retailer"}
          required={true}
          items={dropDownData.incumbent_part_codes.map(
            (val: any, index: any) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            )
          )}
        />
      )}
      {!(nspData.diff_yn !== "Y") && (
        <TextField
          onChange={(e: any) => handleUpdate(e.target.value, "sb_icp")}
          title={"SB ICP"}
          required={true}
          value={nspData.sb_icp}
          id={"sb_icp"}
          disabled={false}
        />
      )}
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "comments")}
        title={"Comments"}
        required={false}
        value={nspData.comments}
        id={"comments"}
        rows={5}
        multiline={true}
        disabled={false}
      />
      <TextButton type={"submit"} title={"Submit"} loading={loading} />
    </form>
  );
};

export default ManageNSPModalEdit;
