import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { navAtom } from "recoil/atoms/nav";
import { headerAtom } from "recoil/atoms/header";
import IconButton from "components/inputs/IconButton";
import { Menu, Close } from "@mui/icons-material";
import logo from "assets/images/recon-logo.png";

const StyledHeader = styled.div`
  height: 60px;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textSecondary};
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
  z-index: 10;

  .left-header {
    display: flex;
    align-items: center;

    .menu-button {
      display: flex;
      justify-content: center;
      width: 70px;
    }

    .header-text {
      display: flex;
      align-items: center;

      .title {
        font-size: 25px;
        margin: 10px;
        color: ${({ theme }) => theme.textSecondary};
      }

      @media only screen and (max-width: 500px) {
        .title {
          font-size: 15px;
          margin: 0px;
        }

        .description {
          display: none;
        }
      }

      .description {
        font-size: 13px;
        margin: 14px 0px;
        color: ${({ theme }) => theme.textSecondary};
      }
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  @media only screen and (max-width: 500px) {
    .right-header {
      font-size: 10px;
      margin: 10px;
    }
  }
`;

const Header = () => {
  const [nav, setNav] = useRecoilState(navAtom);
  const header = useRecoilValue(headerAtom);

  return (
    <StyledHeader>
      <div className={"left-header"}>
        <div className={"menu-button"}>
          {nav ? (
            <IconButton disabled={false} icon={<Close />} />
          ) : (
            <IconButton
              disabled={false}
              icon={<Menu />}
              onClick={() => {
                if (!nav) {
                  setNav(true);
                }
              }}
            />
          )}
        </div>

        <div className="header-text">
          <div className={"title"}>{header.title}</div>
          <div className={"description"}>{header.description}</div>
        </div>
      </div>
      <div className={"right-header"}>
        <img src={logo} alt="NZX" style={{ height: "50%" }} />
      </div>
    </StyledHeader>
  );
};

export default Header;
