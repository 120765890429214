import { TextField } from "@mui/material";
import { CalendarPickerView, DesktopDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const StyledDatePicker = styled.div`
  margin: 0.5rem;
`;

interface Types {
  onChange: (value: string | null, keyboardInputValue?: string | undefined) => void;
  value: any;
  label: string;
  views?: CalendarPickerView[];
  inputFormat?: string;
}

const DatePicker = ({ onChange, value, label, views, inputFormat = "DD-MM-YYYY" }: Types) => {
  return (
    <StyledDatePicker>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label={label}
          inputFormat={inputFormat}
          value={dayjs(value).tz("Pacific/Auckland")}
          onChange={onChange}
          renderInput={(params) => <TextField size="small" {...params} fullWidth />}
          views={views}
          maxDate={"2525-12-31"}
          minDate={"1970-01-01"}
          acceptRegex={/\d{4}-\d{2}-\d{2}/gi}
          disableMaskedInput
        />
      </LocalizationProvider>
    </StyledDatePicker>
  );
};

export default DatePicker;
