import styled from "styled-components";

const StyledNewsInfo = styled.div`
  .nspTable {
    text-align: left;
    border-collapse: collapse;
    font-family: Sans-serif;
    font-size: 12px;
  }
  th {
    background-color: ${({ theme }) => theme.primary};
  }
  .nspTable td,
  .nspTable th {
    padding: 10px;
    border: 1px black;
  }
  .nspTable .noBorder {
    background-color: transparent;
    border: none;
  }
  .addressTable {
    text-align: left;
    border-collapse: collapse;
    font-family: Sans-serif;
    font-size: 12px;
  }
  .addressTable th {
    color: black;
    font-family: Sans-serif;
    font-size: 12px;
  }
  .addressTable td,
  .addressTable th {
    padding: 10px;
    border: 1px black;
  }
`;

interface Types {
  data: any;
}

const DataInfo = ({ data }: Types) => {
  let body = data.body.substring(data.body.indexOf("<body>"), data.body.lastIndexOf("</body>"));

  if (body === "") {
    body = data.body;
  }
  return (
    <StyledNewsInfo
      className="Container"
      dangerouslySetInnerHTML={{ __html: body }}
    ></StyledNewsInfo>
  );
};

export default DataInfo;
