import { format } from "date-fns";

const convertArrayOfObjectsToCSV = (array: any, partCode: any, currentTime: any) => {
  let result: any;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);
  keys.shift(); // Drop the ID from the set of keys

  const dateNow = format(currentTime, "dd/MM/yyyy");
  const timeNow = format(currentTime, "HH:mm:ss");

  result = "";
  result += [
    "HDR",
    "TRADENO",
    "NZRM",
    partCode,
    dateNow,
    timeNow,
    "TPR",
    array.length,
    "GR-XXX",
  ].join(columnDelimiter); // Header
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = (array: any, partCode: string) => {
  const currentTime = new Date();
  const dateNow = format(currentTime, "yyyyMMdd");
  const timeNow = format(currentTime, "HHmmss");

  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, partCode, currentTime);
  if (csv == null) return;

  const filename = `NZRM_E_${partCode}_TRADENO_${dateNow}_${timeNow}`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};
