interface Types {
  title: string;
  message: string;
}
const TableComponent = ({ title, message }: Types) => (
  <tr>
    <th>{title}</th>
    <td>{message}</td>
  </tr>
);

export default TableComponent;
