import FileCheckerInfo from "pages/fileChecker/FileCheckerInfo";
import { useState, useEffect } from "react";
import CheckFile from "pages/fileChecker/CheckFile";
import styled from "styled-components";
import { alertAtom } from "recoil/atoms/alert";
import { Button } from "@mui/material";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { headerAtom } from "recoil/atoms/header";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import { Upload, Replay } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";

const StyledFileChecker = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const FileChecker = () => {
  const setAlert = useSetRecoilState(alertAtom);
  const [data, setData] = useState({
    data: [],
    filetypes: [],
  });
  const [row, setRow] = useState([]);
  const [showUploadInfo, setShowUploadInfo] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [loading, setLoading] = useState(true);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);

  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      type: "string",
      flex: 2,
    },

    {
      field: "short_description",
      headerName: "Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.short_description))],
      flex: 1,
    },
    {
      field: "status_display",
      headerName: "File Status",
      type: "string",
      flex: 1,
    },
    {
      field: "received_datetime",
      headerName: "Date/Time",
      type: "dateTime",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleString("en-NZ"),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "File Checker",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setHeader, showFileUpload]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);
      getData(`/api/v1/file_checker/all_file_checker_files`, setData, setLoading, setAlert);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarExport
            csvOptions={{
              fileName: "file_checker_export",
            }}
          />
          <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
            Refresh
          </Button>
        </div>

        <Button variant="text" size="small" startIcon={<Upload />} onClick={() => setShowFileUpload(true)} disabled={loading} sx={{ marginRight: "1rem" }}>
          Check File
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledFileChecker>
      <Box
        sx={{
          height: "100%",
          "& .super-app-theme--Successful": {
            color: "#004209",
          },
          "& .super-app-theme--Rejected": {
            color: "#860000",
          },
          "& .super-app-theme--Successful with Warnings": {
            color: "#a15600",
          },
        }}
      >
        <DataGrid
          sx={{
            paddingLeft: "1rem",
            paddingTop: "0.5rem",
            border: 0,
            borderRadius: 0,
            background: theme.clearWhite,
            color: theme.textPrimary,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          getRowClassName={(params: any) => `super-app-theme--${params.row.status_display}`}
          rows={data.data}
          columns={columns}
          getRowId={() => uuid()}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          loading={loading}
          onCellClick={(e: any) => {
            setRow(e.row);
            setShowUploadInfo(true);
          }}
        />
      </Box>
      <Modal onClose={() => setShowUploadInfo(false)} open={showUploadInfo} width={"60vw"}>
        <FileCheckerInfo row={row} />
      </Modal>
      <Modal onClose={() => setShowFileUpload(false)} open={showFileUpload} width={"40vw"}>
        <CheckFile fileTypes={data.filetypes} setShow={() => setShowFileUpload(false)} />
      </Modal>
    </StyledFileChecker>
  );
};

export default FileChecker;
