import { useState } from "react";
import styled from "styled-components";
import TextButton from "components/inputs/TextButton";
import TextField from "components/inputs/TextField";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { putData } from "utils/helpers/FetchHelper";

const StyledForgotPassowrd = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .forget-buttons {
    display: flex;
  }
`;

interface Types {
  setShow: any;
}

const ForgotPassword = ({ setShow }: Types) => {
  const [email, setEmail] = useState();
  const setAlert = useSetRecoilState(alertAtom);
  const [loading, setLoading] = useState(false);

  const handleReset = (e: any) => {
    e.preventDefault();
    setLoading(true);

    putData(`/api/v1/password/forgot_password`, JSON.stringify({ email: email }), setLoading, setAlert, setShow);
  };

  return (
    <StyledForgotPassowrd onSubmit={handleReset}>
      <TextField onChange={(e: any) => setEmail(e.target.value)} title={"Email"} required={true} disabled={false} value={email} id={"email"} type={"email"} />
      <div className="forget-buttons">
        <TextButton type={"submit"} title={"Submit"} loading={loading} />

        <TextButton type={"button"} title={"Cancel"} onClick={setShow} />
      </div>
    </StyledForgotPassowrd>
  );
};

export default ForgotPassword;
