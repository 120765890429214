import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue } from "recoil";

const StyledTextField = styled.div`
  background: transparent;
  margin: 0.5rem;
`;

interface Types {
  title: string;
  id: string;
  onChange: any;
  required: boolean;
  disabled: boolean;
  value: any;
  type?: string;
  multiline?: boolean;
  rows?: number;
  regex?: string;
  regextText?: string;
}

const TextFields = ({
  onChange,
  title,
  required,
  disabled,
  value,
  id,
  type = "text",
  multiline,
  rows,
  regex,
  regextText,
}: Types) => {
  const theme = useRecoilValue(themeAtom);
  return (
    <StyledTextField>
      <TextField
        sx={{ input: { color: theme.textPrimary }, label: { color: theme.inputLabelColor } }}
        fullWidth
        inputProps={{ pattern: regex, title: regextText }}
        title={title}
        size={"small"}
        id={id}
        label={title}
        onChange={onChange}
        required={required}
        value={value}
        variant="outlined"
        disabled={disabled}
        type={type}
        multiline={multiline}
        rows={rows}
      />
    </StyledTextField>
  );
};

export default TextFields;
