import Cookies from "universal-cookie";
export const cookie = new Cookies();

export const setUser = (val: any, options?: any) => {
  cookie.set("_recon", val, options);
};

export const removeUser = () => {
  cookie.remove("_recon");
};

export const getUser = () => {
  const user = cookie.get("_recon");
  return user ? user : defaultUser;
};

interface Types {
  id: string;
  forename: string;
  surname: string;
  username: string;
  part_code: string;
  admin: boolean;
  authorised_to_nsp_ops: boolean;
  jwt: string;
  logged_in: boolean;
}

const defaultUser: Types = {
  id: "",
  forename: "",
  surname: "",
  username: "",
  part_code: "",
  admin: false,
  authorised_to_nsp_ops: false,
  jwt: "",
  logged_in: false
};
