import { useEffect, useState } from "react";
import TableComponent from "components/helperComponents/TableComponent";
import { nspStatus, nspIslandName, nspReconMethod, nspFlowDirection } from "utils/helpers/Helper";
import InfoComponent from "components/helperComponents/InfoComponent";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";
import { getData } from "utils/helpers/FetchHelper";

interface Types {
  row: any;
}
const NSPLogInfo = ({ row }: Types) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);

  const columns = [
    {
      field: "POC_CODE",
      headerName: "NSP",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            {[params.row.POC_CODE, params.row.NWK_CODE, params.row.RTYP_CODE]
              .filter(Boolean)
              .join(", ")}
          </div>
        );
      },
    },
    {
      field: "I_FLOW_YN",
      headerName: "Flow",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return <div>{nspFlowDirection(params.row.I_FLOW_YN, params.row.X_FLOW_YN)}</div>;
      },
    },
    {
      field: "BALA_CODE",
      headerName: "Balancing Area",
      type: "string",
      flex: 1,
    },
    {
      field: "EMBEDDED_UNDER_POC_CODE",
      headerName: "Parent GXP",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            {[params.row.EMBEDDED_UNDER_POC_CODE, params.row.EMBEDDED_UNDER_NWK_CODE]
              .filter(Boolean)
              .join(", ")}
          </div>
        );
      },
    },
    {
      field: "SB_ICP",
      headerName: "SB",
      type: "string",
      flex: 1,
    },
    {
      field: "NEW_START_DATE",
      headerName: "Start",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>{`${new Date(params.row.NEW_START_DATE).toLocaleDateString("en-NZ")} (${
            params.row.NEW_START_TP
          })`}</div>
        );
      },
    },
    {
      field: "NEW_END_DATE",
      headerName: "End",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>{`${new Date(params.row.NEW_END_DATE).toLocaleDateString("en-NZ")} (${
            params.row.NEW_END_TP
          })`}</div>
        );
      },
    },
  ];

  useEffect(() => {
    getData(`/api/v1/nsp/nsp_change_log_info/${row.gnch_id}`, setData, setLoading, setAlert);
  }, [row.gnch_id, setAlert]);

  console.log(data);

  return (
    <InfoComponent>
      <table>
        <tbody>
          <TableComponent title={"Reference number:"} message={row.gnch_id} />
          <TableComponent title={"Operation:"} message={row.operation} />
          <TableComponent title={"Status:"} message={nspStatus(row.nste_code)} />
          <TableComponent title={"POC:"} message={row.poc_code} />
          <TableComponent title={"Network code:"} message={row.nwk_code} />
          <TableComponent title={"Recon type:"} message={row.rtyp_code} />
          <TableComponent
            title={"Flow direction:"}
            message={nspFlowDirection(row.x_flow_yn, row.i_flow_yn)}
          />
          <TableComponent title={"Balance code:"} message={row.bala_code} />
          <TableComponent title={"Parent NSP:"} message={row.embedded_under_nsp_code} />
          <TableComponent title={"LE ICP:"} message={row.le_icp} />
          <TableComponent title={"Address:"} message={row.address} />
          <TableComponent title={"Island:"} message={nspIslandName(row.island)} />
          <TableComponent
            title={"Start date:"}
            message={new Date(row.start_date).toLocaleDateString("en-NZ")}
          />
          <TableComponent title={"Start TP:"} message={row.start_tp} />
          <TableComponent
            title={"End date:"}
            message={new Date(row.end_date).toLocaleDateString("en-NZ")}
          />
          <TableComponent title={"End TP:"} message={row.end_tp} />
          <TableComponent title={"Recon method:"} message={nspReconMethod(row.diff_yn)} />
          <TableComponent title={"SB ICP:"} message={row.sb_icp} />
          <TableComponent title={"SB retailer:"} message={row.incumbent_part_code} />
          <TableComponent title={"Comments:"} message={row.comments} />
        </tbody>
      </table>

      <div style={{ height: "250px" }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={() => uuid()}
          getRowHeight={() => "auto"}
          disableSelectionOnClick
          loading={loading}
        />
      </div>
    </InfoComponent>
  );
};

export default NSPLogInfo;
