import { useState, useEffect } from "react";
import TradeNotificationModalNew from "pages/tradeNotifications/TradeNotificationModalNew";
import TradeNotificationModalEdit from "pages/tradeNotifications/TradeNotificationModalEdit";
import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { Button } from "@mui/material";
import { themeAtom } from "recoil/atoms/theme";
import { headerAtom } from "recoil/atoms/header";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import { Add, Download, Replay } from "@mui/icons-material";
import { downloadCSV } from "pages/tradeNotifications/DownloadCsv";
import { getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";

const StyledTradeNotifications = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const TradeNotifications = () => {
  const [newTN, setNewTN] = useState(false);
  const [editTN, setEditTN] = useState(false);
  const [row, setRow] = useState(undefined);
  const setAlert = useSetRecoilState(alertAtom);
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);
  const [data, setData] = useState({
    dropDowns: [],
    data: [],
    can_edit_trade_notification: false,
    show_edit_and_new_trade_notification: false,
  });

  const columns = [
    {
      field: "ctrt_code",
      headerName: "Contract",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.ctrt_code))],
      flex: 1,
    },
    {
      field: "contract_type",
      headerName: "Contract Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.contract_type))],
      flex: 1,
    },
    {
      field: "part_code",
      headerName: "Participant",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.part_code))],
      flex: 1,
    },
    {
      field: "nsp",
      headerName: "NSP",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.nsp))],
      flex: 1,
    },
    {
      field: "rtyp_code",
      headerName: "Recon Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.rtyp_code))],
      flex: 1,
    },
    {
      field: "metering_type",
      headerName: "Metering Type",
      type: "singleSelect",
      valueOptions: [...new Set(data.data.map((item: any) => item.metering_type))],
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      type: "dateTime",
      flex: 1,
      renderCell: (params: any) => {
        return <div>{`${new Date(params.row.start_date).toLocaleDateString("en-NZ")} (${params.row.start_tp})`}</div>;
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      type: "dateTime",
      flex: 1,
      renderCell: (params: any) => {
        return <div>{`${new Date(params.row.end_date).toLocaleDateString("en-NZ")} (${params.row.end_tp})`}</div>;
      },
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Trade Notifications",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setHeader, newTN, editTN]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);

      getData(`/api/v1/trade_notifications/all_trade_notifcations`, setData, setLoading, setAlert);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
            Refresh
          </Button>
          <Button variant="text" size="small" startIcon={<Download />} onClick={() => downloadCSV(data.data, user.part_code)} disabled={loading}>
            Download CSV
          </Button>
        </div>
        {data.show_edit_and_new_trade_notification && (
          <Button variant="text" size="small" startIcon={<Add />} onClick={() => setNewTN(true)} disabled={loading}>
            New Trade Notification
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  const handleCellClick = (row: any) => {
    if (data.show_edit_and_new_trade_notification) {
      setRow(row);
      setEditTN(true);
    }
  };

  return (
    <StyledTradeNotifications>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data.data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          handleCellClick(e.row);
        }}
      />
      <Modal onClose={() => setEditTN(false)} open={editTN} width={"50vw"}>
        <TradeNotificationModalEdit dropDowns={data.dropDowns} row={row} setShow={() => setEditTN(false)} />
      </Modal>
      <Modal onClose={() => setNewTN(false)} open={newTN} width={"50vw"}>
        <TradeNotificationModalNew dropDowns={data.dropDowns} setShow={() => setNewTN(false)} tradeNotifications={data.data} />
      </Modal>
    </StyledTradeNotifications>
  );
};

export default TradeNotifications;
