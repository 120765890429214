import { useState } from "react";
import TextButton from "components/inputs/TextButton";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextField from "components/inputs/TextField";
import { putData } from "utils/helpers/FetchHelper";
import styled from "styled-components";

const StyledChangePassword = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface Types {
  onClose: () => void;
  userId: string;
}

const PreferencePasswordModal = ({ onClose, userId }: Types) => {
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);

  const [passwordData, setPasswordData] = useState({
    id: userId,
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const handleUpdate = (value: any, field: any) => {
    setPasswordData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (passwordData.new_password !== passwordData.new_password_confirmation) {
      setAlert({
        type: "error",
        message: "Passwords must match",
        timeout: 5000,
      });
      setLoading(false);
      return;
    }

    putData(
      `/api/v1/user/update_password`,
      JSON.stringify(passwordData),
      setLoading,
      setAlert,
      onClose
    );
  };

  return (
    <StyledChangePassword onSubmit={handleSubmit}>
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "current_password")}
        title={"Current Password"}
        required={true}
        disabled={false}
        value={passwordData.current_password}
        id={"current_password"}
        type={"password"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "new_password")}
        title={"New Password"}
        required={true}
        disabled={false}
        value={passwordData.new_password}
        id={"new_password"}
        type={"password"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "new_password_confirmation")}
        title={"Confirm new Password"}
        required={true}
        disabled={false}
        value={passwordData.new_password_confirmation}
        id={"new_password_confirmation"}
        type={"password"}
      />
      <TextButton type={"submit"} title={"Change Password"} loading={loading} />
    </StyledChangePassword>
  );
};

export default PreferencePasswordModal;
