import { useState, useEffect } from "react";
import ManageNSPModalEdit from "pages/nspManage/ManageNSPModalEdit";
import ManageNSPModalNew from "pages/nspManage/ManageNSPModalNew";
import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { Button } from "@mui/material";
import { themeAtom } from "recoil/atoms/theme";
import { headerAtom } from "recoil/atoms/header";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import { Add, Replay } from "@mui/icons-material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { useNavigate } from "react-router";

const StyledManageNSP = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const ManageNSP = () => {
  const navigate = useNavigate();
  const [newNSP, setNewNSP] = useState(false);
  const [editNSP, setEditNSP] = useState(false);
  const [row, setRow] = useState(undefined);
  const setAlert = useSetRecoilState(alertAtom);
  const [loading, setLoading] = useState(true);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);
  const [data, setData] = useState({
    nsp_data: [],
    drop_down_data: [],
    grid_owner: false,
  });

  const columns = [
    {
      field: "poc_code",
      headerName: "POC code",
      type: "string",
      flex: 1,
    },
    {
      field: "nwk_code",
      headerName: "Network code",
      type: "string",
      flex: 1,
    },
    {
      field: "rtyp_code",
      headerName: "Recon Type",
      type: "string",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      type: "date",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleDateString("en-NZ"),
    },
    {
      field: "end_date",
      headerName: "End Date",
      type: "date",
      flex: 1,
      valueFormatter: (v: any) => new Date(v.value).toLocaleDateString("en-NZ"),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Manage NSP",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [editNSP, newNSP, setHeader]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);
      getData(`/api/v1/nsp/all_nsp`, setData, setLoading, setAlert, () => navigate("/"));
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarExport
            csvOptions={{
              fileName: "manage_nsp_export",
            }}
          />
          <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
            Refresh
          </Button>
        </div>

        <Button variant="text" size="small" startIcon={<Add />} onClick={() => setNewNSP(true)} disabled={loading} sx={{ marginRight: "1rem" }}>
          Add NSP
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledManageNSP>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data.nsp_data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          setRow(e.row);
          setEditNSP(true);
        }}
      />

      <Modal onClose={() => setEditNSP(false)} open={editNSP} width={"40vw"}>
        <ManageNSPModalEdit dropDownData={data.drop_down_data} row={row} setShow={() => setEditNSP(false)} gridOwner={data.grid_owner} />
      </Modal>
      <Modal onClose={() => setNewNSP(false)} open={newNSP} width={"40vw"}>
        <ManageNSPModalNew dropDownData={data.drop_down_data} setShow={() => setNewNSP(false)} />
      </Modal>
    </StyledManageNSP>
  );
};

export default ManageNSP;
