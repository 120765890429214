import styled from "styled-components";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextButton from "components/inputs/TextButton";
import { getUser } from "utils/helpers/CookiesHelper";

const UploadFileForm = styled.form`
  display: flex;
  align-items: flex-end;
  margin: 0px 10px;
  justify-content: space-between;

  .upload-styles {
    display: flex;
    align-items: center;
  }
`;

interface Types {
  setShow: any;
}

const UploadFile = ({ setShow }: Types) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const setAlert = useSetRecoilState(alertAtom);
  const [loading, setLoading] = useState(false);

  const fileChange = (e: any) => {
    e.persist();
    setSelectedFile(e.target.files[0]);
  };

  const submitFile = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData();
    form.append("submission", selectedFile);

    if (selectedFile !== null) {
      fetch(`/api/v1/uploads/create_upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getUser().jwt}`,
        },
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.alert.type === "success") {
            setShow();
          }
          setAlert({
            type: data.alert.type,
            message: data.alert.message,
            timeout: 5000,
          });
          setLoading(false);
        })
        .catch(() => {
          setAlert({
            type: "error",
            message: "Error making request, please try again",
            timeout: 5000,
          });
          setLoading(false);
        });
    }
  };

  return (
    <UploadFileForm onSubmit={submitFile}>
      <div className={"upload-styles"}>
        <TextButton title={"Upload File"} component={"label"} type={"button"}>
          <input type="file" accept=".csv" hidden onChange={fileChange} />
        </TextButton>

        {selectedFile ? `${selectedFile.name}` : "No File Chosen"}
      </div>
      <TextButton
        type={"submit"}
        title={"Submit"}
        loading={loading}
        disabled={selectedFile === null}
      />
    </UploadFileForm>
  );
};

export default UploadFile;
