import NavItem from "components/navbar/NavItem";
import NavItemChildren from "components/navbar/NavItemChildren";
import NavItemOnClick from "components/navbar/NavItemOnClick";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { navAtom } from "recoil/atoms/nav";
import { getUser, cookie } from "utils/helpers/CookiesHelper";
import {
  Login,
  Logout,
  Person,
  Home,
  Work,
  Gavel,
  Call,
  Bolt,
  Download,
  Feed,
  InsertDriveFile,
  AdminPanelSettings,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import Modal from "components/modal/Modal";
import LogIn from "pages/logInOut/LogIn";
import { useLocation } from "react-router-dom";
import { setUser } from "utils/helpers/CookiesHelper";

const StyledNavbar = styled.div<StyleTypes>`
  width: ${(props) => props.width};
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.sidenav};
  color: ${({ theme }) => theme.textSecondary};
  transition: 0.5s ease-out;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 10;

  .scrollable-menu {
    ul {
      padding: 0px;
      margin: 0px;
    }
  }

  @media only screen and (min-width: 640px) {
    min-width: 70px;
  }
`;

interface StyleTypes {
  width: string;
}

const useOutsideAlerter = (ref: any, nav: any, setNav: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (nav) {
          setNav(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [nav, ref, setNav]);
};

const Navbar = () => {
  const [nav, setNav] = useRecoilState(navAtom);
  const [userState, setUserState] = useState(getUser());
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (getUser().logged_in) {
      setUser(getUser(), {
        path: "/",
        secure: true,
        maxAge: 60 * 60 * 2,
      });
    } else {
      setUserState(getUser());
    }
  }, [location]);

  cookie.addChangeListener(() => {
    setUserState(getUser());
  });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, nav, setNav);

  return (
    <StyledNavbar width={nav ? "220px" : "0px"} ref={wrapperRef}>
      <Modal onClose={() => setShowLogin(false)} open={showLogin} width={"30vw"}>
        <LogIn setShowLogin={setShowLogin} />
      </Modal>
      <div className={"scrollable-menu"}>
        <ul>
          <NavItem
            title={"Log Out"}
            icon={<Logout />}
            isOpen={nav}
            to={"/log_out"}
            show={userState.logged_in ? true : false}
          />

          <NavItemOnClick
            title={"Log In"}
            icon={<Login />}
            isOpen={nav}
            onClick={() => setShowLogin(true)}
            show={userState.logged_in ? false : true}
          />

          <NavItem title={"Home"} icon={<Home />} isOpen={nav} to={"/"} show={true} />

          <NavItem
            title={
              userState.logged_in
                ? userState.forename +
                  " " +
                  userState.surname.substring(0, 1) +
                  " (" +
                  userState.part_code +
                  ")"
                : ""
            }
            icon={<Person />}
            isOpen={nav}
            to={"/preferences"}
            show={userState.logged_in ? true : false}
          />
          <NavItem title={"News"} icon={<Feed />} isOpen={nav} to={"/news"} show={true} />
          <NavItem
            title={"Public Files"}
            icon={<InsertDriveFile />}
            isOpen={nav}
            to={"/public_files"}
            show={true}
          />
          <NavItem
            title={"Volume Disputes"}
            icon={<Bolt />}
            isOpen={nav}
            to={"/volume_dispute_logs"}
            show={userState.logged_in ? true : false}
          />

          <NavItemChildren
            title={"File Manager"}
            icon={<Work />}
            isOpen={nav}
            show={userState.logged_in ? true : false}
            children={[
              {
                title: "Uploads",
                path: "/upload_files",
              },
              {
                title: "Downloads",
                path: "/download_files",
              },
              {
                title: "File Checker",
                path: "/checker_files",
              },
              {
                title: "Trade Notifications",
                path: "/trade_notifications",
              },
            ]}
          />
          <NavItemChildren
            title={"NSP Changes"}
            icon={<Gavel />}
            isOpen={nav}
            show={userState.logged_in && userState.authorised_to_nsp_ops}
            children={[
              {
                title: "Manage NSP",
                path: "/manage_nsp",
              },
              {
                title: "Logs",
                path: "/nsp_log",
              },
              {
                title: "NSP Meter Updates",
                path: "/nsp_meter_updates",
              },
            ]}
          />

          <NavItem
            title={"Admin"}
            icon={<AdminPanelSettings />}
            isOpen={nav}
            to={"/users"}
            show={userState.logged_in && userState.admin}
          />
          <NavItem title={"Contact Us"} icon={<Call />} isOpen={nav} to={"/contact"} show={true} />
          <NavItem
            title={"Resources"}
            icon={<Download />}
            isOpen={nav}
            to={"/resources"}
            show={true}
          />
        </ul>
      </div>
    </StyledNavbar>
  );
};

export default Navbar;
