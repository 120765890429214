import { atom } from "recoil";

export const defaultLightTheme = {
  black: "#00000094",
  white: "#ffffff",
  background: "#F7F9FC",
  primary: "rgb(3, 97, 161)",
  sidenav: "linear-gradient(0deg, rgb(52, 154, 218), rgb(3, 97, 161) 82% 100%)",
  secondary: "#b3d7ec",
  textPrimary: "#000000",
  textSecondary: "#ffffff",
  clearWhite: "#ffffffe5",
  disabled: "#6e6e6e",
  disabledInputs: "#d0d0d0",
  inputLabelColor: "#5f5f5f",
};

export const themeAtom = atom({
  key: "theme",
  default: defaultLightTheme,
});
