import TableComponent from "components/helperComponents/TableComponent";
import InfoComponent from "components/helperComponents/InfoComponent";

interface Types {
  data: any;
}
const DownloadInfo = ({ data }: Types) => {
  return (
    <InfoComponent>
      <table>
        <tbody>
          <TableComponent title={"Filename:"} message={data.filename} />
          <TableComponent title={"Uploaded by User:"} message={data.uploaded_by_user} />
          <TableComponent title={"Uploaded by Participant:"} message={data.uploaded_by_part} />
          <TableComponent title={"Recipient:"} message={data.recipient} />
          <TableComponent title={"File Type:"} message={data.short_description} />
          <TableComponent title={"Run Date/Time:"} message={data.run_datetime} />
          <TableComponent title={"Received Date/Time:"} message={data.received_datetime} />
          <TableComponent title={"State:"} message={data.status_display} />
          <TableComponent title={"Acceptance date:"} message={data.acceptance_date} />
          <TableComponent title={"Detail rows:"} message={data.detail_rows} />
          <TableComponent title={"Validation Errors:"} message={data.warning_rows} />
          <TableComponent title={"Comments:"} message={data.comments} />
        </tbody>
      </table>
    </InfoComponent>
  );
};

export default DownloadInfo;
