import TextField from "components/inputs/TextField";
import { useEffect, useState } from "react";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "components/inputs/DatePicker";
import { removeEmptyString } from "utils/helpers/Helper";
import TextButton from "components/inputs/TextButton";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { putData } from "utils/helpers/FetchHelper";
import { updateDate, convertDates } from "utils/helpers/Helper";
import { checkForInvalidDate } from "utils/helpers/Helper";

interface Types {
  setShow: () => void;
  mepsProviders: any;
  row: any;
}

const NSPMeterUpdateModal = ({ setShow, row, mepsProviders }: Types) => {
  const [loading, setLoading] = useState(false);
  const setAlert = useSetRecoilState(alertAtom);
  const [mep, setMep] = useState({
    metering_provider_code: row.metering_provider_code ? row.metering_provider_code : "",
    expiry_date: row.expiry_date ? row.expiry_date : "",
    cert_status: row.cert_status ? row.cert_status : "",
  });

  const handleUpdate = (value: any, field: any) => {
    setMep((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  useEffect(() => {
    if (mep.cert_status === "Exempt") {
      handleUpdate("", "metering_provider_code");
    }
  }, [mep.cert_status]);

  const updateNSPMeter = (e: any) => {
    e.preventDefault();

    const res = checkForInvalidDate(mep);
    if (res !== "") {
      setAlert({
        type: "error",
        message: `${res} must be a valid date`,
        timeout: 5000,
      });
      return;
    }

    setLoading(true);

    if (new Date(mep.expiry_date) < new Date()) {
      setAlert({
        type: "error",
        message: "Expiry date must be greater than todays date",
        timeout: 5000,
      });
      setLoading(false);
      return;
    }

    const nspData = {
      metering_provider_code: mep.metering_provider_code,
      mtrg_exempt_expiry_date: mep.cert_status === "Exempt" ? mep.expiry_date : null,
      certification_expiry_date: mep.cert_status === "Exempt" ? null : mep.expiry_date,
    };

    putData(
      `/api/v1/nsp/update_meps/${row.nsp_code}`,
      JSON.stringify(removeEmptyString(convertDates(nspData))),
      setLoading,
      setAlert,
      setShow
    );
  };

  return (
    <form onSubmit={updateNSPMeter}>
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "nsp_code")}
        title={"NSP"}
        required={false}
        disabled={true}
        value={row.nsp_code}
        id={"nsp_code"}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "description")}
        title={"Description"}
        required={false}
        disabled={true}
        value={row.description}
        id={"description"}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "responsible_party_code")}
        title={"Responsible participant"}
        required={false}
        disabled={true}
        value={row.responsible_party_code}
        id={"responsible_party_code"}
      />

      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "cert_status");
        }}
        value={mep.cert_status}
        id={"cert_status"}
        label={"Certification Status"}
        disabled={false}
        items={["Certified", "Exempt"].map((val: any, index: any) => (
          <MenuItem key={index} value={val}>
            {val}
          </MenuItem>
        ))}
      />

      {mep.cert_status === "Certified" && (
        <DropDown
          onchange={(e: any) => {
            handleUpdate(e.target.value, "metering_provider_code");
          }}
          value={mep.metering_provider_code}
          id={"cert_status"}
          label={"MEP"}
          items={mepsProviders.map((val: any, index: any) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        />
      )}

      <DatePicker
        onChange={(e: any) => updateDate(e, handleUpdate, "expiry_date")}
        value={new Date(mep.expiry_date)}
        label={"Expiry Date"}
      />
      <TextButton type={"submit"} title={"Confirm"} loading={loading} />
    </form>
  );
};

export default NSPMeterUpdateModal;
