import IconButton from "components/inputs/IconButton";
import styled from "styled-components";
import { Download } from "@mui/icons-material";

const CenteredTd = styled.td`
  text-align: center;
`;

interface Types {
  title: string;
  summery: string;
  downloadLink: string;
}

const ResourceTableComponent = ({ title, summery, downloadLink }: Types) => {
  return (
    <tr>
      <td>{title}</td>
      <td className={"hide-col"}>{summery}</td>
      <CenteredTd>
        <a href={downloadLink} download>
          <IconButton icon={<Download />} disabled={false} color={"black"} />
        </a>
      </CenteredTd>
    </tr>
  );
};

export default ResourceTableComponent;
