import { useState } from "react";
import styled from "styled-components";
import TextButton from "components/inputs/TextButton";
import TextField from "components/inputs/TextField";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import ForgotPassword from "pages/resetPassword/ForgotPassword";
import { removeNullValues } from "utils/helpers/Helper";
import { setUser } from "utils/helpers/CookiesHelper";

const StyledLogin = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  .forgot-password {
    display: flex;
    margin: 0.5rem;
    font-size: 0.8rem;

    .forgot-password-reset {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      padding-left: 0.2rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
interface Types {
  setShowLogin: Function;
}

const LogIn = ({ setShowLogin }: Types) => {
  const setAlert = useSetRecoilState(alertAtom);
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
  });

  const handleUpdate = (value: any, field: string) => {
    setLoginInfo((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    fetch(`/api/v1/login_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginInfo),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.alert.type === "success") {
          //Set the user in cookies with a 2 hour timeout
          setUser(removeNullValues(data.content), {
            path: "/",
            secure: true,
            maxAge: 60 * 60 * 2,
          });
          setShowLogin(false);
        }
        setAlert({
          type: data.alert.type,
          message: data.alert.message,
          timeout: 5000,
        });
        setLoading(false);
      })
      .catch(() => {
        setAlert({
          type: "error",
          message: "Error making request, please try again",
          timeout: 5000,
        });
        setLoading(false);
      });
  };

  return login ? (
    <StyledLogin onSubmit={handleSubmit}>
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "username")}
        title={"Username"}
        required={true}
        disabled={false}
        value={loginInfo.username}
        id={"username"}
        type={"text"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "password")}
        title={"Password"}
        required={true}
        disabled={false}
        value={loginInfo.password}
        id={"password"}
        type={"password"}
      />

      <div className="forgot-password">
        Forgotton Password?
        <div onClick={() => setLogin(false)} className="forgot-password-reset">
          Reset It
        </div>
      </div>

      <TextButton type={"submit"} title={"Sign In"} loading={loading} />
    </StyledLogin>
  ) : (
    <ForgotPassword setShow={() => setLogin(true)} />
  );
};

export default LogIn;
