import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

const StyledIconButton = styled.div`
  background: transparent;
  border: none;
`;

interface Types {
  icon: any;
  onClick?: any;
  disabled: boolean;
  title?: string;
  color?: string;
}

const IconButtons = ({ icon, title, onClick, disabled, color = "white" }: Types) => {
  return (
    <StyledIconButton>
      <IconButton
        onClick={onClick}
        title={title}
        disabled={disabled}
        className={"button"}
        style={{ color: color }}
      >
        {icon}
      </IconButton>
    </StyledIconButton>
  );
};

export default IconButtons;
