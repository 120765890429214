import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { alertAtom } from "recoil/atoms/alert";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useEffect } from "react";

const StyledAlert = styled.div`
  z-index: 10000;
  position: fixed;
  padding: 10px;

  right: 0px;
  animation-name: slide;
  animation-duration: 1s;
  animation-iteration-count: 1;

  @keyframes slide {
    from {
      right: -1000px;
    }
    to {
      right: 0px;
    }
  }
`;

const AlertMessage = () => {
  const [alert, setAlert] = useRecoilState(alertAtom);

  let type: any = alert.type!;

  useEffect(() => {
    if (alert.timeout === 0) {
      return;
    } else {
      const interval = setInterval(() => {
        setAlert({
          type: "",
          message: "",
          timeout: 0,
        });
      }, alert.timeout);

      return () => {
        clearInterval(interval);
      };
    }
  }, [alert, setAlert]);

  return type !== "" ? (
    <StyledAlert>
      <Alert
        onClose={() =>
          setAlert({
            type: "",
            message: "",
            timeout: 0,
          })
        }
        severity={type}
      >
        <AlertTitle>{type.charAt(0).toUpperCase() + type.slice(1)}</AlertTitle>
        {alert.message}
      </Alert>
    </StyledAlert>
  ) : (
    <></>
  );
};
export default AlertMessage;
