import { useState, useEffect } from "react";
import VolumeDisputeInfo from "pages/volumeDisputes/VolumeDisputeInfo";
import VolumeDisputeModal from "pages/volumeDisputes/VolumeDisputeModal";
import { nspStatus } from "utils/helpers/Helper";
import styled from "styled-components";
import { alertAtom } from "recoil/atoms/alert";
import { v4 as uuid } from "uuid";
import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button } from "@mui/material";
import { Bolt, Replay } from "@mui/icons-material";
import Modal from "components/modal/Modal";
import { headerAtom } from "recoil/atoms/header";
import { getData } from "utils/helpers/FetchHelper";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { getUser } from "utils/helpers/CookiesHelper";
import { decode } from "html-entities";

const StyledHeader = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const VolumeLog = () => {
  const [data, setData] = useState([]);
  const [showNewDispute, setShowNewDispute] = useState(false);
  const [row, setRow] = useState([]);
  const [rowOpen, setRowOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const theme = useRecoilValue(themeAtom);

  const columns = [
    {
      field: "raised_by",
      headerName: "Raised by",
      type: "string",
      flex: 1,
      valueFormatter: (v: any) => decode(v.value, { level: "html5" }),
    },
    {
      field: "vlds_id",
      headerName: "Ref number",
      type: "number",
      flex: 1,
    },
    {
      field: "created_date",
      headerName: "Created on",
      type: "string",
      flex: 1,
    },
    {
      field: "nste_code",
      headerName: "Status",
      type: "string",
      flex: 1,
      valueFormatter: (v: any) => nspStatus(v.value),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Volume Disputes",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setAlert, setHeader, showNewDispute]);

  const fetchData = () => {
    setLoading(true);
    if (getUser().logged_in) {
      getData(`/api/v1/volume_disputes/all_volume_disputes`, setData, setLoading, setAlert);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarExport
            csvOptions={{
              fileName: "volume_disputes_export",
            }}
          />
          <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
            Refresh
          </Button>
        </div>

        <Button variant="text" size="small" startIcon={<Bolt />} onClick={() => setShowNewDispute(true)} disabled={loading} sx={{ marginRight: "1rem" }}>
          New Dispute
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledHeader>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          background: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          setRow(e.row);
          setRowOpen(true);
        }}
      />
      <Modal onClose={() => setRowOpen(false)} open={rowOpen} width={"40vw"}>
        <VolumeDisputeInfo data={row} />
      </Modal>
      <Modal onClose={() => setShowNewDispute(false)} open={showNewDispute} width={"40vw"}>
        <VolumeDisputeModal setShow={() => setShowNewDispute(false)} />
      </Modal>
    </StyledHeader>
  );
};

export default VolumeLog;
