import styled from "styled-components";
import { useEffect, useState } from "react";

const StyledNewsComponent = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;

  .news-component {
    display: block;
    text-align: left;
    padding: 1rem;

    .nspTable {
      text-align: left;
      border-collapse: collapse;
      font-family: Sans-serif;
      font-size: 12px;
    }
    th {
      background-color: ${({ theme }) => theme.primary};
    }
    .nspTable td,
    .nspTable th {
      padding: 10px;
      border: 1px black;
    }
    .nspTable .noBorder {
      background-color: transparent;
      border: none;
    }
    .addressTable {
      text-align: left;
      border-collapse: collapse;
      font-family: Sans-serif;
      font-size: 12px;
    }
    .addressTable th {
      color: black;
      font-family: Sans-serif;
      font-size: 12px;
    }
    .addressTable td,
    .addressTable th {
      padding: 10px;
      border: 1px black;
    }
  }
`;

const StyledDots = styled.div`
  display: flex;
  padding: 0.5rem;

  .active {
    background-color: ${({ theme }) => theme.secondary};
  }

  div {
    margin: 0.3rem 0.5rem;
    padding: 1px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    box-shadow: rgb(99 99 99 / 85%) 0px 2px 8px 0px;
    background-color: ${({ theme }) => theme.primary};

    &:hover {
      background-color: ${({ theme }) => theme.primary};
      cursor: pointer;
    }
  }
`;

interface Types {
  data: Array<{
    article_sub_type: string;
    article_type: string;
    body: string;
    created_at: string;
    display_order: string | null;
    id: number;
    subject: string;
  }>;
}

const Carousel = ({ data }: Types) => {
  const [index, setIndex] = useState(0);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hover) {
        if (index < data.length - 1) {
          setIndex((v) => v + 1);
        } else {
          setIndex(0);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [data.length, index, hover]);

  const createDots = () => {
    const rows: Array<any> = [];
    for (let i = 0; i < data.length; i++) {
      if (i === index) {
        rows.push(
          <div
            className={"active"}
            key={i}
            onClick={() => {
              setIndex(i);
            }}
          />
        );
      } else {
        rows.push(
          <div
            key={i}
            onClick={() => {
              setIndex(i);
            }}
          />
        );
      }
    }

    return <StyledDots>{rows}</StyledDots>;
  };

  return (
    <StyledNewsComponent onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {createDots()}

      <div
        key={data[index].id}
        className="news-component"
        dangerouslySetInnerHTML={{
          __html: data[index].body.substring(
            data[index].body.indexOf("<body>"),
            data[index].body.lastIndexOf("</body>")
          ),
        }}
      />
    </StyledNewsComponent>
  );
};

export default Carousel;
