import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextButton from "components/inputs/TextButton";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { removeEmptyString } from "utils/helpers/Helper";
import TextField from "components/inputs/TextField";
import { postData } from "utils/helpers/FetchHelper";

const StyledConfirm = styled.form`
  .user-form-buttons {
    display: flex;
  }
`;

interface Types {
  setShow: any;
  participants: any;
}

const NewUser = ({ setShow, participants }: Types) => {
  const setAlert = useSetRecoilState(alertAtom);

  const [userData, setUserData] = useState({
    forename: "",
    surname: "",
    part_code: "",
    company_name: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    country: "",
    postcode: "",
    mobile: "",
    phone: "",
    failed_login_count: 0,
    username: "",
    password: "",
    password_confirmation: "",
  });

  const [loading, setLoading] = useState(false);

  const handleUpdate = (value: any, field: any) => {
    setUserData((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const updateUser = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (userData.password !== userData.password_confirmation) {
      setAlert({
        type: "error",
        message: "Passwords must match",
        timeout: 5000,
      });
      setLoading(false);
      return;
    }

    postData(
      `/api/v1/user/create`,
      JSON.stringify(removeEmptyString(userData)),
      setLoading,
      setAlert,
      setShow
    );
  };

  return (
    <StyledConfirm onSubmit={updateUser}>
      <DropDown
        onchange={(e: any) => {
          handleUpdate(e.target.value, "part_code");
          const name = participants.find((o: any) => o.code === e.target.value).name;
          handleUpdate(name ? name : "", "company_name");
        }}
        required={true}
        value={userData.part_code}
        id={"part_code"}
        label={"Participant"}
        items={participants.map((val: { code: string; name: string }, index: number) => (
          <MenuItem key={index} value={val.code}>
            {`${val.code} | ${val.name}`}
          </MenuItem>
        ))}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "username")}
        title={"Username"}
        required={true}
        disabled={false}
        value={userData.username}
        id={"username"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "password")}
        title={"Password"}
        required={true}
        disabled={false}
        value={userData.password}
        id={"password"}
        type={"password"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "password_confirmation")}
        title={"Password Confirmation"}
        required={true}
        disabled={false}
        value={userData.password_confirmation}
        id={"passwordConfirmation"}
        type={"password"}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "forename")}
        title={"Name"}
        required={true}
        disabled={false}
        value={userData.forename}
        id={"forename"}
      />

      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "surname")}
        title={"Surname"}
        required={true}
        disabled={false}
        value={userData.surname}
        id={"surname"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "email")}
        title={"Email"}
        required={true}
        disabled={false}
        value={userData.email}
        id={"email"}
        type={"email"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "mobile")}
        title={"Mobile"}
        required={false}
        disabled={false}
        value={userData.mobile}
        id={"mobile"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "phone")}
        title={"Phone"}
        required={false}
        disabled={false}
        value={userData.phone}
        id={"phone"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address1")}
        title={"Address 1"}
        required={false}
        disabled={false}
        value={userData.address1}
        id={"address1"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address2")}
        title={"Address 2"}
        required={false}
        disabled={false}
        value={userData.address2}
        id={"address2"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "address3")}
        title={"Address 3"}
        required={false}
        disabled={false}
        value={userData.address3}
        id={"address3"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "city")}
        title={"City"}
        required={false}
        disabled={false}
        value={userData.city}
        id={"city"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "country")}
        title={"country"}
        required={false}
        disabled={false}
        value={userData.country}
        id={"country"}
      />
      <TextField
        onChange={(e: any) => handleUpdate(e.target.value, "postcode")}
        title={"Postcode"}
        required={false}
        disabled={false}
        value={userData.postcode}
        id={"postcode"}
      />

      <div className="user-form-buttons">
        <TextButton type={"submit"} title={"Submit"} loading={loading} />
      </div>
    </StyledConfirm>
  );
};

export default NewUser;
