import styled from "styled-components";
import ResourceTableComponent from "pages/resources/ResourceTableComponent";
import { headerAtom } from "recoil/atoms/header";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import Balancing_Area_Notification_Form_and_Guide from "assets/documents/Balancing_Area_Notification_Form_and_Guide-v1.13.pdf";
import Profile_Notification_Form_and_Guide from "assets/documents/Profile_Notification_Form_and_Guide-v1.13.pdf";
import Residual_Volume_ICP_Notification_Form_and_Guide from "assets/documents/Residual_Volume_ICP_Notification_Form_and_Guide-v1.4.pdf";
import Trading_Notification_Form_Guide from "assets/documents/Trading_Notification_Form_Guide-v1.14.pdf";
import Trading_Notification_Form from "assets/documents/Trading_Notification_Form-v1.13.pdf";
import NZX_RM_User_Guide from "assets/documents/NZX_RM_User_Guide_v2.pdf";
import RM_File_Names_and_File_Formats from "assets/documents/RM_File_Names_and_File_Formats_v3.1.pdf";
import Gifted_Generation_List from "assets/documents/Gifted_Generation_List.pdf";
import Reconciliation_manager_functional_specification from "assets/documents/Reconciliation_manager_functional_specification.pdf";
import Reconciliation_101 from "assets/documents/Reconciliation_101_2023.pdf";

const ResourceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  color: ${({ theme }) => theme.textPrimary};

  .resource-info {
    padding: 1rem;
    width: 75vw;
    box-sizing: border-box;
    background: ${({ theme }) => theme.clearWhite};

    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin: 2rem 0;
  }
`;

const StyledResourceTable = styled.div`
  border-collapse: collapse;
  width: 75vw;
  background: ${({ theme }) => theme.clearWhite};
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

  table {
    padding: 1rem;
    width: 100%;
  }

  th {
    text-align: left;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.textSecondary};
  }
  td,
  th {
    padding: 0.2rem;
  }

  @media only screen and (max-width: 500px) {
    .hide-col {
      display: none;
    }
  }
`;

const Resources = () => {
  const setHeader = useSetRecoilState(headerAtom);
  useEffect(() => {
    setHeader({
      title: "Resources",
      description: "",
    });
  }, [setHeader]);

  return (
    <ResourceContainer>
      <div className={"resource-info"}>
        The below documents are designed to assist with fulfilling your reconciliation obligations.
        They include:
        <ul>
          <li>
            reference material to better navigate the portal and to ensure a better understanding of
            the complexities of electricity volume reconciliation.
          </li>
          <li>forms required to notify the Reconciliation Manager of specific changes.</li>
        </ul>
        If you require further help or information please get in touch.
      </div>

      <StyledResourceTable>
        <table>
          <tbody>
            <tr>
              <th>Title</th>
              <th className={"hide-col"}>Summary</th>
              <th>Download</th>
            </tr>
            <ResourceTableComponent
              title={"Reconciliation 101"}
              summery={"Overview of the reconciliation processes."}
              downloadLink={Reconciliation_101}
            />
            <ResourceTableComponent
              title={"Balancing Area Notification Form and Guide"}
              summery={"Distributors to provide Balancing Area information."}
              downloadLink={Balancing_Area_Notification_Form_and_Guide}
            />
            <ResourceTableComponent
              title={"Profile Notification Form and Guide"}
              summery={"Profile Owners to provide the RM with profile information."}
              downloadLink={Profile_Notification_Form_and_Guide}
            />
            <ResourceTableComponent
              title={"Residual Volume ICP Notification Form and Guide"}
              summery={"Embedded Network owners to provide SB ICP."}
              downloadLink={Residual_Volume_ICP_Notification_Form_and_Guide}
            />
            <ResourceTableComponent
              title={"Trading Notification Form Guide"}
              summery={"Information pertaining to Trade Notifications."}
              downloadLink={Trading_Notification_Form_Guide}
            />
            <ResourceTableComponent
              title={"Trading Notification Form"}
              summery={"Participants to notify the RM of new Trading Notifications."}
              downloadLink={Trading_Notification_Form}
            />
            <ResourceTableComponent
              title={"User Manual"}
              summery={"General information regarding interacting with the reconciliation manager."}
              downloadLink={NZX_RM_User_Guide}
            />
            <ResourceTableComponent
              title={"File Names and File Formats"}
              summery={"Formats and information regarding submission and result files."}
              downloadLink={RM_File_Names_and_File_Formats}
            />
            <ResourceTableComponent
              title={"Gifted Generation Register"}
              summery={
                "Central Register of Gifted Generation ICPs. Contact us to revise or add to."
              }
              downloadLink={Gifted_Generation_List}
            />
            <ResourceTableComponent
              title={"Functional Specification "}
              summery={"Reconciliation Manager Functional Details."}
              downloadLink={Reconciliation_manager_functional_specification}
            />
          </tbody>
        </table>
      </StyledResourceTable>
    </ResourceContainer>
  );
};
export default Resources;
