import { useState } from "react";
import styled from "styled-components";
import DropDown from "components/inputs/DropDown";
import MenuItem from "@mui/material/MenuItem";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import TextButton from "components/inputs/TextButton";
import { getUser } from "utils/helpers/CookiesHelper";

const CheckFileForm = styled.form`
  display: flex;
  align-items: flex-end;
  margin: 0px 10px;
  justify-content: space-between;

  .file-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .upload-styles {
    display: flex;
    flex-direction: column;
  }
`;

interface Types {
  fileTypes: Array<string>;
  setShow: () => void;
}

const CheckFile = ({ fileTypes, setShow }: Types) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFilteType, setSelectedFilteType] = useState("");
  const setAlert = useSetRecoilState(alertAtom);
  const [loading, setLoading] = useState(false);

  const fileChange = (e: any) => {
    e.persist();
    setSelectedFile(e.target.files[0]);
  };

  const submitFile = (e: any) => {
    e.preventDefault();
    setLoading(true);
    
    if (selectedFile === null || selectedFilteType === "") {
      setAlert({
        type: "error",
        message: "File and File Type must be selected",
        timeout: 5000,
      });

      setLoading(false);
    } else {
      const form = new FormData();
      form.append("submission", selectedFile);
      form.append("filetype", selectedFilteType);

      fetch(`/api/v1/file_checker/validate_file`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getUser().jwt}`,
        },
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.alert.type === "success") {
            setShow();
          }
          setAlert({
            type: data.alert.type,
            message: data.alert.message,
            timeout: 5000,
          });
          setLoading(false);
        })
        .catch(() => {
          setAlert({
            type: "error",
            message: "Error making request, please try again",
            timeout: 5000,
          });
          setLoading(false);
        });
    }
  };

  return (
    <CheckFileForm onSubmit={submitFile}>
      <div className={"upload-styles"}>
        <div className="file-picker">
          <TextButton title={"Upload File"} type={"button"} component={"label"}>
            <input type="file" accept=".csv" hidden onChange={fileChange} />
          </TextButton>
          {selectedFile ? `${selectedFile.name}` : "No File Chosen"}
        </div>
        <DropDown
          onchange={(e: any) => {
            setSelectedFilteType(e.target.value);
          }}
          value={selectedFilteType}
          id={"file_type"}
          label={"File Type"}
          items={fileTypes.map((val: any, index: any) => (
            <MenuItem key={index} value={val.code}>
              {val.name}
            </MenuItem>
          ))}
        />
      </div>
      <TextButton
        type={"submit"}
        title={"Submit"}
        loading={loading}
        disabled={selectedFile === null}
      />
    </CheckFileForm>
  );
};

export default CheckFile;
