import { useEffect, useState } from "react";
import EditUser from "pages/admin/EditUser";
import NewUser from "pages/admin/NewUser";

import { Button } from "@mui/material";
import styled from "styled-components";
import { alertAtom } from "recoil/atoms/alert";
import { headerAtom } from "recoil/atoms/header";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import { Replay, Person } from "@mui/icons-material";

import { themeAtom } from "recoil/atoms/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getData } from "utils/helpers/FetchHelper";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { removeNullValues } from "utils/helpers/Helper";
import { getUser } from "utils/helpers/CookiesHelper";
import { useNavigate } from "react-router";
import { decode } from "html-entities";

const StyledHeader = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const Admin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [row, setRow] = useState([]);
  const [data, setData] = useState({
    user: [],
    participant_list: [],
  });
  const theme = useRecoilValue(themeAtom);
  const setAlert = useSetRecoilState(alertAtom);
  const setHeader = useSetRecoilState(headerAtom);

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      type: "string",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 1,
    },
    {
      field: "forename",
      headerName: "Forename",
      type: "string",
      flex: 1,
    },
    {
      field: "surname",
      headerName: "Surname",
      type: "string",
      flex: 1,
    },
    {
      field: "part_code",
      headerName: "Participant Code",
      type: "string",
      flex: 1,
    },
    {
      field: "company_name",
      headerName: "Company Name",
      type: "string",
      flex: 1,
      valueFormatter: (v: any) => decode(v.value, { level: "html5" }),
    },
  ];

  useEffect(() => {
    setHeader({
      title: "Admin",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [editUser, newUser, setHeader]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);
      getData(`/api/v1/user/all_users_and_participants`, setData, setLoading, setAlert, () => navigate("/"));
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
          <GridToolbarExport
            csvOptions={{
              fileName: "users_export",
            }}
          />
          <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
            Refresh
          </Button>
        </div>

        <Button variant="text" size="small" startIcon={<Person />} onClick={() => setNewUser(true)} disabled={loading} sx={{ marginRight: "1rem" }}>
          New User
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledHeader>
      <DataGrid
        sx={{
          paddingLeft: "1rem",
          paddingTop: "0.5rem",
          border: 0,
          borderRadius: 0,
          backgroundColor: theme.clearWhite,
          color: theme.textPrimary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        rows={data.user}
        columns={columns}
        getRowId={() => uuid()}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        onCellClick={(e: any) => {
          setRow(e.row);
          setEditUser(true);
        }}
      />
      <Modal onClose={() => setEditUser(false)} open={editUser} width={"40vw"}>
        <EditUser setShow={() => setEditUser(false)} row={removeNullValues(row)} participants={data.participant_list} />
      </Modal>
      <Modal onClose={() => setNewUser(false)} open={newUser} width={"40vw"}>
        <NewUser setShow={() => setNewUser(false)} participants={data.participant_list} />
      </Modal>
    </StyledHeader>
  );
};

export default Admin;
