import { atom } from "recoil";

export const alertAtom = atom({
  key: "alert",
  default: {
    type: "",
    message: "",
    timeout: 0,
  },
});
