import styled from "styled-components";
import { useState, useEffect } from "react";
import TextButton from "components/inputs/TextButton";
import MenuItem from "@mui/material/MenuItem";
import PreferencePasswordModal from "pages/preference/PreferencePasswordModal";
import DropDown from "components/inputs/DropDown";
import { removeEmptyString } from "utils/helpers/Helper";
import { useSetRecoilState } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { headerAtom } from "recoil/atoms/header";
import TextField from "components/inputs/TextField";
import Modal from "components/modal/Modal";
import { getData, putData } from "utils/helpers/FetchHelper";
import LoadingSpinner from "components/loaders/LoadingSpinner";
import { getUser, setUser } from "utils/helpers/CookiesHelper";

const StyledPreferences = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  .profile {
    background-color: ${({ theme }) => theme.primary};
    width: 11rem;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 5rem;
    flex-direction: column;
    color: ${({ theme }) => theme.textSecondary};
    margin-top: 1rem;

    .company-code {
      font-size: 1rem;
    }
  }

  .form-styles {
    margin-top: 1rem;
    width: 60vw;
    min-width: 320px;
    background: ${({ theme }) => theme.clearWhite};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  }
`;

const StyledResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 0.5rem 2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: baseline;
  width: 90%;
  padding: 1rem;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Preferences = () => {
  const user = getUser();
  const setAlert = useSetRecoilState(alertAtom);
  const [edit, setEdit] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const [participantList, setParticipantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const setHeader = useSetRecoilState(headerAtom);

  const handleUpdate = (value: any, field: any) => {
    setUserInfo((lp: any) => {
      let tmp = { ...lp };
      tmp[field] = value;
      return tmp;
    });
  };

  const setUserAndPartlist = (data: any) => {
    setUserInfo(data.user);
    setParticipantList(data.participant_list);
  };

  const setUserAndPartlistAndCookie = (data: any) => {
    setUserInfo(data.user);
    setParticipantList(data.participant_list);

    let cookieData = {
      id: data.user.id,
      forename: data.user.forename,
      surname: data.user.surname,
      username: data.user.username,
      part_code: data.user.part_code,
      company_name: data.user.company_name,
      admin: user.admin,
      authorised_to_nsp_ops: data.user.authorised_nsp_ops,
      jwt: user.jwt,
      logged_in: true,
    };

    setUser(cookieData, {
      path: "/",
      secure: true,
      maxAge: 60 * 60 * 2,
    });
  };

  useEffect(() => {
    setHeader({
      title: "Preferences",
      description: "",
    });
    if (getUser().logged_in) {
      getData(`/api/v1/user/find_current_user`, setUserAndPartlist, setLoadingUser, setAlert);
    }
  }, [setAlert, setHeader]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const userId = { id: userInfo.id };

    putData(
      `/api/v1/user/update`,
      JSON.stringify(
        removeEmptyString({
          ...userId,
          ...userInfo,
        })
      ),
      setLoading,
      setAlert,
      thenRun
    );
  };

  const thenRun = () => {
    getData(
      `/api/v1/user/find_current_user`,
      setUserAndPartlistAndCookie,
      setLoadingUser,
      setAlert
    );
    setEdit(false);
  };

  return (
    <StyledPreferences>
      <form className="form-styles" onSubmit={handleSubmit}>
        <div className="profile">
          {userInfo.forename.substring(0, 1) + "" + userInfo.surname.substring(0, 1)}
          <div className={"company-code "}> {"(" + userInfo.part_code + ")"}</div>
        </div>
        {loadingUser ? (
          <LoadingSpinner />
        ) : (
          <StyledResponsiveGrid>
            <DropDown
              onchange={(e: any) => {
                const val: any = participantList.find(
                  (x: { name: string; code: string }) => x.code === e.target.value
                );
                handleUpdate(e.target.value, "part_code");
                handleUpdate(val.name, "company_name");
              }}
              value={userInfo.part_code}
              id={"part_code"}
              label={"Participant"}
              disabled={edit && userInfo.admin ? false : true}
              items={participantList.map((val: any, index: any) => (
                <MenuItem key={index} value={val.code}>
                  {`${val.code} | ${val.name}`}
                </MenuItem>
              ))}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "forename")}
              title={"Forename"}
              required={true}
              disabled={!edit}
              value={userInfo.forename}
              id={"forename"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "surname")}
              title={"Surname"}
              required={true}
              disabled={!edit}
              value={userInfo.surname}
              id={"surname"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "username")}
              title={"Username"}
              required={true}
              disabled={!edit}
              value={userInfo.username}
              id={"username"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "email")}
              title={"Email"}
              required={true}
              disabled={!edit}
              value={userInfo.email}
              id={"email"}
              type={"email"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "address1")}
              title={"Address1"}
              required={false}
              disabled={!edit}
              value={userInfo.address1}
              id={"address1"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "address2")}
              title={"Address2"}
              required={false}
              disabled={!edit}
              value={userInfo.address2}
              id={"address2"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "address3")}
              title={"Address3"}
              required={false}
              disabled={!edit}
              value={userInfo.address3}
              id={"address3"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "city")}
              title={"City"}
              required={false}
              disabled={!edit}
              value={userInfo.city}
              id={"city"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "country")}
              title={"Country"}
              required={false}
              disabled={!edit}
              value={userInfo.country}
              id={"country"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "postcode")}
              title={"Postcode"}
              required={false}
              disabled={!edit}
              value={userInfo.postcode}
              id={"postcode"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "mobile")}
              title={"Mobile"}
              required={false}
              disabled={!edit}
              value={userInfo.mobile}
              id={"mobile"}
            />
            <TextField
              onChange={(e: any) => handleUpdate(e.target.value, "phone")}
              title={"Phone"}
              required={false}
              disabled={!edit}
              value={userInfo.phone}
              id={"phone"}
            />
            {userInfo.admin && (
              <TextField
                onChange={(e: any) => handleUpdate(e.target.value, "failed_login_count")}
                title={"Failed login count"}
                required={false}
                disabled={!edit}
                value={userInfo.failed_login_count}
                id={"failed_login_count"}
              />
            )}
            <div>
              {edit && (
                <div style={{ display: "flex" }}>
                  <TextButton type={"submit"} title={"Save"} loading={loading} />
                  <TextButton
                    type={"button"}
                    title={"Cancel"}
                    onClick={() => {
                      setUserInfo(userInfo);
                      setEdit(false);
                    }}
                  />
                </div>
              )}

              {!edit && (
                <div style={{ display: "flex" }}>
                  <TextButton type={"button"} title={"Edit"} onClick={() => setEdit(true)} />
                  <TextButton
                    type={"button"}
                    title={"Change Password"}
                    onClick={() => setEditPassword(true)}
                  />
                </div>
              )}
            </div>
          </StyledResponsiveGrid>
        )}
      </form>

      <Modal onClose={() => setEditPassword(false)} open={editPassword} width={"40vw"}>
        <PreferencePasswordModal onClose={() => setEditPassword(false)} userId={userInfo.id} />
      </Modal>
    </StyledPreferences>
  );
};
export default Preferences;
