export const range = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const hash = (string: string) => {
  var hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    let ch = string.charCodeAt(i);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash;
  }
  return hash;
};

export const nspStatus = (nste: any) => {
  switch (nste) {
    case "A":
      return "ACCEPTED";
    case "R":
      return "REJECTED";
    default:
      return "IN PROGRESS";
  }
};

export const nspIslandName = (islandCode: any) => {
  switch (islandCode) {
    case "NI":
      return "North Island";
    case "SI":
      return "South Island";
    default:
      return "unknown";
  }
};

export const nspReconMethod = (diff_yn: any) => {
  switch (diff_yn) {
    case "Y":
      return "DIFFERENCING";
    case "N":
      return "GLOBAL";
    default:
      return "unknown";
  }
};

export const nspFlowDirection = (x_flow: any, i_flow: any) => {
  switch (`${x_flow}${i_flow}`) {
    case "YY":
      return "X and I";
    case "YN":
      return "X only";
    case "NY":
      return "I only";
    default:
      return "";
  }
};

export const nspFlowDirectionUndo = (flow: any) => {
  switch (flow) {
    case "X and I":
      return { x_flow_yn: "Y", i_flow_yn: "Y" };
    case "I only":
      return { x_flow_yn: "N", i_flow_yn: "Y" };
    default:
      return { x_flow_yn: "Y", i_flow_yn: "N" };
  }
};

export const removeEmptyString = (object: any) => {
  Object.keys(object).forEach((k) => (object[k] = object[k] === "" ? null : object[k]));
  return object;
};

export const removeNullValues = (object: any) => {
  Object.keys(object).forEach((k) => (object[k] = object[k] === null ? "" : object[k]));
  return object;
};

export const updateDate = (val: any, handleUpdate: Function, field: string) => {
  if (val && !isNaN(Date.parse(val.toString()))) {
    const yd = new Date(val)
    const offset = yd.getTimezoneOffset();
    const newDate = new Date(yd.getTime() - (offset*60*1000)).toISOString().split('T')[0]

    if (newDate && !isNaN(Date.parse(newDate.toString()))) {
      handleUpdate(new Date(newDate).toISOString().split('T')[0], field);
    } else {
      handleUpdate("Invalid Date", field);
    }
  } else {
    handleUpdate("Invalid Date", field);
  }
};

export const convertDates = (filter: any) => {
  let dateAttributes = ["start_date", "end_date", "expiry_date"];
  let tmp = { ...filter };

  Object.keys(tmp).forEach((k) => {
    if (dateAttributes.includes(k)) {
      tmp[k] = tmp[k].split("T")[0];
    }
  });
  return tmp;
};

function formatTitle(string: string) {
  const to_return = string.replace("_", " ");
  return to_return.charAt(0).toUpperCase() + to_return.slice(1);
}

function outsideBounds(value: string) {
  const selected = new Date(value)
  const maxDate = new Date("2525-12-31")
  const minDate = new Date("1970-01-01")

  if(selected > maxDate || selected < minDate){
    return true
  } else {
    return false
  }
}

export const checkForInvalidDate = (filter: any) => {
  let dateAttributes = ["start_date", "end_date", "expiry_date"];
  let tmp = { ...filter };
  let toReturn = "";

  Object.keys(tmp).forEach((k) => {
    if (dateAttributes.includes(k)) {
      if (tmp[k] === "Invalid Date" || outsideBounds(tmp[k])) {
        toReturn === "" ? (toReturn += formatTitle(k)) : (toReturn += ` and ${formatTitle(k)}`);
      }
    }
  });
  return toReturn;
};
